import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Retiro.module.css";


const PanelSpinner = () => {

	return (
		<section className="text-center">
			{
            	Array.from([1, 2, 3, 4, 5, 6, 7]).map((item, key) => 
            		<section key={key} className={`${styles["option-container-white"]}`}>
						<div className="w-100 mb-4">
							<div className={styles["option-item"]}>
								<p className={styles["item-text__title-fact"]}> <Skeleton width={180} height={20} count={1} /></p>
								<p className={`${styles["option-details__view"]} `}><Skeleton width={100} height={15} count={1} /></p>
							</div>
						</div>
					</section>
	            )
	        }
		</section>
	)
}

export default PanelSpinner


