import { useState } from "react"
import { saveFromPackage, nextFormPreOrden, deleteRowTablePackage,
	editRowTablePackage, saveRowTablePackage, resetDestination, saveFormRetiro, addNewPackages } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"


export const usePackage = (toast) => {

	const dispatch = useDispatch()
	const { infoDestination } = useSelector((state) => state.preorden)
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit, errors, reset, getValues, setError, control } = useForm()

	const [ nroPeso, setNroPeso ] = useState("");
	const [ nroLargo, setNroLargo] = useState("");
	const [ nroAncho, setNroAncho ] = useState("");
	const [ nroAlto, setNroAlto ] = useState("");


	const onSubmit =   (data, event) => {
		try{
			
			const response = validatePackage(data)
			if(response){
				setNroPeso("");
				setNroLargo("");
				setNroAncho("");
				setNroAlto("");
			}
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const lastFormPreOrden = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	const nextForm = (event, nameForm) => {
		event.preventDefault()
	
		if(infoDestination[0].listPackages.length===0){
			const data = getValues()

			let response = validatePackage(data)
		
			if(response){
				dispatch(nextFormPreOrden(nameForm))
			}
		}else{
			dispatch(nextFormPreOrden(nameForm))
		}
	}

	const deleteRow = (event, index) => {
		event.preventDefault()
		dispatch(deleteRowTablePackage(index))
	}

	const editRow = (event, index, visible) => {
		event.preventDefault()

		if(visible===false){
			dispatch(editRowTablePackage(index))
		}else{
			const data = getValues()
		
			const item = data.cargas[index]
		
			if(item.tall!=="" && item.width!=="" && item.long!=="" && item.count!=="" && item.overall_weight!=="" && item.bulto!==""){
				dispatch(saveRowTablePackage(item, index))
			}else{
				toast.error("Debe completar los datos", {position: toast.POSITION.TOP_RIGHT})
			}
		}
	}

	const validatePackage = (data) => {
		let packages = infoDestination[0]?.listPackages
		let response = { listPackages: packages }

		if(data.alto!=="" && data.ancho!=="" && data.largo!=="" && data.bulto!=="" && data.peso_total!==""){
			let total = 0
			if(data?.alto!==""){
				total = parseFloat(data?.alto)
			}
			if(data?.ancho!==""){
				total = total * parseFloat(data?.ancho)
			}
			if(data?.largo!==""){
				total = total * parseFloat(data?.largo)
			}
			data = {
				...data,
				metraje_cubico: parseFloat(total).toFixed(2)
			}
			let body = [ ...packages, data]
			response = dispatch(addNewPackages(body))
			if(response.status){
				reset({
					tall: "",
				    width: "",
				    long: "",
				    count: "",
				    overall_weight: "",
					bulto: ""
				})
			}
		}
	
		if(response.listPackages.length===0){
			toast.error("Agregue las características del paquete", {position: toast.POSITION.TOP_RIGHT})
			setError("alto", { type: "manual", message: "Requerido"})
			setError("ancho", { type: "manual", message: "Requerido"})
			setError("largo", { type: "manual", message: "Requerido"})
			setError("peso_total", { type: "manual", message: "Requerido"})
			setError("bulto", { type: "manual", message: "Requerido"})
			return false
		}
		return true 
	}

	const addNewDestination = () => {
		const data = getValues()
		let response = validatePackage(data)
		if(response){
			dispatch(saveFromPackage(typeof data?.observation === "undefined" ? "" : data?.observation))
			dispatch(resetDestination())
			dispatch(nextFormPreOrden("destination"))
		}
	}

	const nextFormCompleted = async (event) => {
		event.preventDefault()

		setLoading(true)
		const data = getValues()

		let validation = validatePackage(data)

		if(validation){
			dispatch(saveFromPackage(typeof data?.observation === "undefined" ? "" : data?.observation))
			dispatch(resetDestination())

			const response = await dispatch(saveFormRetiro())
			setLoading(false)

			if(response.status){
				dispatch(nextFormPreOrden("completed"))
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}
		}
		setLoading(false)
	}

	return {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		lastFormPreOrden,
		infoDestination,
		deleteRow,
		editRow,
		nextForm,
		addNewDestination,
		nextFormCompleted,
		loading,
		control,
		nroPeso,
		setNroPeso,
		nroLargo, 
		setNroLargo,
		nroAncho, 
		setNroAncho,
		nroAlto, 
		setNroAlto,
		getValues
	}
}