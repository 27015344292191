import { useEffect, useState } from "react"
import { getDataInitialClaim, getSelectedClaim, resetFormClaim, loadingSearch, updateListClaim, updateStatusMessage } from "services/actions/claimAction"
import { useDispatch, useSelector } from "react-redux"

export const useClaim = (toast) => {

	const dispatch = useDispatch()
	const { listClaimsSearch, loadingClaim, modalName, claimSelected, loadingModal } = useSelector((state) => state.claims)
	const [ show, setShow ] = useState(false)
	const [ showModal, setShowModal ] = useState(false)
	const [ titleModal, setTitleModal ] = useState(false)

	const getInitial = async () => {
		if(listClaimsSearch.length===0){
			await dispatch(getDataInitialClaim())
		}		
	}

	const handleClose = () => {
		setShow(!show)
		
	}

	const openModalByType = (formNext) => {
		dispatch(resetFormClaim(formNext))
		handleCloseModal()
	}

	const viewClaim = async (nro_message, title, is_notification) => {
		dispatch(loadingSearch(true))
		handleClose()
	
		const body = {
			conversation_id: nro_message
		}
		const response = await dispatch(getSelectedClaim(body))
		
		if(response?.status){
			setTitleModal(title)
			if(is_notification){
				dispatch(updateListClaim(nro_message))
				await dispatch(updateStatusMessage(body))
			}
			
		}	
		dispatch(loadingSearch(false))
	}

	const handleCloseModal = () => {
		setShowModal(!showModal)
	}


	useEffect(() => {
		getInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const state = {
		listClaimsSearch,
		loadingClaim,
		show,
		claimSelected,
		modalName,
		showModal,
		titleModal,
		loadingModal
	}

	const actions = {
		handleClose,
		openModalByType,
		viewClaim,
		handleCloseModal
	}

	return {
		state,
		actions
	}
}