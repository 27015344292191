import API from 'services/api/index';
import { 
	LIST_OFFICE,
	SELECTED_COMUNA,
	LOADING_COMUNA,
	SELECTED_SUCURSAL,
	CHANGE_URL_MAPA
} from 'services/types/OfficeType';
import endPoints from 'services/routes/';


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getDataInitial = () => async (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_COMUNA, true));	

		let response = await API.get(endPoints.office.info);

		const { status, data } = response.data

		if(status){
			let sucursalSelected = {}
			let dataOffice = data
			let urlMap = ""

			for(let index in dataOffice){
				
				let ubication = dataOffice[index]?.ubicacion ? dataOffice[index]?.ubicacion?.map(ubi => {
					return {
						...ubi,
						email: ubi.email==="" ? "No disponible" : ubi.email,
						telefonoArray: ubi.telefono==="" ? ["No disponible"] : ubi.telefono.split(";"),
						horarioArray: typeof ubi.horario==="undefined" ? ["No disponible"] : ubi?.horario?.split(";")
					}
				}) : [{
					titulo: "No disponible",
					estatus: "Inactivo",
					direccion: "No disponible",
					telefono: "No disponible",
					mapa: "",
					email: "No disponible",
					telefonoArray: ["No disponible"],
					horarioArray: ["No disponible"],
				}]
				dataOffice[index].ubicacion=ubication

			}

			if(dataOffice.length > 0){
				sucursalSelected = dataOffice[0]
				urlMap = dataOffice[0]?.ubicacion[0]?.mapa
			}

			const payload = {
				sucursales: dataOffice,
				sucursalSelected,
				comunaSelected: "",
				loadingComuna: false,
				urlMap
			}
			
			dispatch(requestSuccess(LIST_OFFICE, payload));
		}		
		
    }catch(error){

      return {status: false, message:error}
    }
};

export const getUrlMapa = (urlMapa) =>  (dispatch, getState) => {
	try{
		const payload = {urlMapa}

		dispatch(requestSuccess(CHANGE_URL_MAPA, payload));	
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const getComunaSelected = (comuna_id) =>  (dispatch, getState) => {
	try{
		const { sucursalSelected } = getState().offices

		dispatch(requestSuccess(LOADING_COMUNA, true));	
		let comuna = sucursalSelected?.comunas?.find(item =>parseInt(item.id)===parseInt(comuna_id))

		let comunaSelected = {}

		if(comuna){
			comunaSelected = {...comuna,  sucursal: sucursalSelected.nombre }
		}

		const payload = {
			comunaSelected,
			loadingComuna: false
		}

		dispatch(requestSuccess(SELECTED_COMUNA, payload));	
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const getSucursalSelected = (sucursal_id) =>  (dispatch, getState) => {
	try{
		const { sucursales } = getState().offices

		dispatch(requestSuccess(LOADING_COMUNA, true));	
		let sucursal = sucursales?.find(item =>parseInt(item.id)===parseInt(sucursal_id))

		let sucursalSelected = {}
		let comunaSelected = {}

		if(sucursal){
			sucursalSelected = {
				...sucursal,  
				comunas: sucursal.comunas.map(item => {
					return {
						...item,
						sucursal: sucursal.nombre
					}
				})
			}
			comunaSelected= sucursal.comunas[0]
		}

		const payload = {
			sucursalSelected,
			comunaSelected,
			loadingComuna: false,
			urlMap: sucursalSelected?.ubicacion[0]?.mapa
		}

		dispatch(requestSuccess(SELECTED_SUCURSAL, payload));	
		
    }catch(error){
      return {status: false, message:error}
    }
};