import OrdenItem from "components/Withdrawals/OrdenItem";
import ContainerWithdrawalsMob from "components/Withdrawals/ContainerWithdrawalsMob";

import PanelSpinner from "components/Custom/Spinner/PanelSpinner";
import styles from "styles/Retiro.module.css";


const PanelInformationMob = ({ title, listOrden, subtitle, optClassName, isVisibleHeader, 
	isVisibleTitle, downloadFile=null, isLoadingDashboard }) => {

	return (
		<div className={`${styles["opt-container"]} ${styles[optClassName]}`}>
			<div className="mt-3">
				{
					isVisibleHeader && (
						<div className={styles["group-item"]}>
							<p className={`${styles["group-title"]} ${styles["group-title__calendar"]}`}>{title}</p>
							<p className={styles["group-view"]}>{subtitle}</p>
						</div>
					)
				}
				{isLoadingDashboard && (<PanelSpinner />)}
				{
					isVisibleTitle && (
						<OrdenItem 
							fecha="Fecha"
							cliente="Nombre quien retira"
							tracking="No. de tracking"
							title_btn="Ver orden"
						 />
					)
				}
				
				<ContainerWithdrawalsMob listOrden={listOrden} downloadFile={downloadFile} />
			</div>
		</div>
	);
};

export default PanelInformationMob;