import styles from "styles/Retiro.module.css";

const OrdenItemInvoice = ({ number_invoice, date_created, cliente, total, status, url, 
	downloadFile, classStatus }) => {

	return (
		<section className={`${styles["option-container"]} ${styles["border-ttracking"]}`}>
			<div className="w-100">
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>Número de factura</p>
					<p className={styles["item-text__title-fact"]}>No. {number_invoice}</p>
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>Fecha</p>
					<p className={styles["item-text__title-fact"]}>{date_created}</p>
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>Nombre / Razón social</p>
					<p className={styles["item-text__title-fact"]}>{cliente}</p>
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>Monto</p>
					<p className={styles["item-text__title-fact"]}>${total}</p>
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>Estatus</p>
					<div className={styles[classStatus.option1]}>
			      		<div className={styles[classStatus.option2]}></div>
			      		{status}
			      	</div>
				</div>
			</div>
			<div className={`w-100 mt-3 ${styles["option-item"]}`}>
				<button 
					type="button"
			  		className="btn btn-primary btn-size-33 w-50 ml-auto btn-block mb-3 font-10" 
			  		onClick={(event)=>downloadFile(event, url, number_invoice)}
			  	>
		           Descargar
		        </button>
			</div>
		</section>
	);
};

export default OrdenItemInvoice;