import React from "react";

import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import VerdanaRegular from 'assets/fonts/Verdana.ttf'
import VerdanaRegularItalic from 'assets/fonts/Verdana-Italic.ttf'
import VerdanaBold from 'assets/fonts/Verdana-Bold.ttf'
import VerdanaBoldItalic from 'assets/fonts/Verdana-Bold-Italic.ttf'
//import QRCode from "react-qr-code";
// Register font
Font.register({ 
  family: 'Verdana',
  fonts: [
    {
      src: VerdanaRegular,
    },
    {
      src: VerdanaBold,
      fontWeight: 'bold',
    },
    {
      src: VerdanaBoldItalic,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    {
      src: VerdanaRegularItalic,
      fontWeight: 'normal',
      fontStyle: 'italic',
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Verdana',

  },
	title: {
		fontSize: '7px',
    color: '#23447e',
    padding: '0px',
    marginBottom: '0px',
    fontWeight: '700'
	},
  subtitle: {
    fontSize: '7px',
    padding: '0px',
    fontWeight: 'normal'
  },
  divider: {
    width: '49%',
    textAlign: 'center',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 0,
    paddingBottom: 0,
  },
  sectionFooter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    height: '40px',
    marginTop: 0
  },
  count: {
    fontSize: '9px',
    color: '#000',
    padding: '0px',
    marginBottom: '0px',
    fontWeight: '700'
  },
  middleDivider: {
    width: '100%',
    height: '30px'
  },
  imagen: {
    width: '50px',
    height: '50px',
  }
});

const TicketPreOrden = ({ sucursal_name, comuna_name, ciudad_destino_nombre, oficina_destino_nombre, razon_social_remitente,
  razon_social_destinatario, direccion_destinatario, total_bultos, imagen }) => {


  return (
    
       <Page size={[212.59,113.38]} style={styles.page}>
          <View style={styles.section}>
            <View style={{...styles.divider, height: '26px', textAlign: 'left'}}>
                  <Text style={styles.title}>ORIGEN</Text>
                  <Text style={styles.subtitle}>{`${sucursal_name} - ${comuna_name}`}</Text>
            </View>
            <View style={{...styles.divider, height: '26px'}}>
                  <Text  style={styles.title}>DESTINO</Text>
                  <Text style={styles.subtitle}>{`${ciudad_destino_nombre} - ${oficina_destino_nombre}`}</Text>
            </View>
          </View>
          <View style={{...styles.section, height: '60px'}}>
            <View style={{...styles.divider, textAlign: 'left'}}>
              <View style={{...styles.middleDivider}}>
                    <Text style={styles.title}>REMITENTE</Text>
                    <Text style={styles.subtitle}>{razon_social_remitente}</Text>
              </View>
              <View style={styles.middleDivider}>
                    <Text style={styles.title}>DESTINATARIO</Text>
                    <Text style={styles.subtitle}>{razon_social_destinatario}</Text>
              </View>
            </View>
            <View style={{...styles.divider, height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Image 
                style={styles.imagen}
                src={`data:image/png;base64,${imagen}`}
              />
            </View>
          </View>
          <View style={{...styles.section}}>
            <View style={{...styles.divider, height: '26px', textAlign: 'left'}}>
                  <Text style={styles.title}>DIRECCIÓN</Text>
                  <Text style={styles.subtitle}>{direccion_destinatario}</Text>
            </View>
            <View style={{...styles.divider, height: '26px'}}>
                  <Text  style={styles.title}>CANTIDAD</Text>
                  <Text style={styles.subtitle}>{total_bultos} BULTOS</Text>
            </View>
          </View>
        </Page>

  );
};

export default TicketPreOrden;