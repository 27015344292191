import { resetPasswordProfile } from "services/actions/userAction"

import md5 from "md5"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"


export const useFormPassword = (toast) => {

    const dispatch = useDispatch()
    const [ isLoading, setIsLoading ] = useState(false)
    const { register, handleSubmit, errors, reset } = useForm({})

    const onSubmit =  async (data, event) => {
        event.preventDefault()

        try{
            setIsLoading(true)
            if(data.passwordCurrent.length<6){
                setIsLoading(false)
                toast.error("La contraseña actual deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
                return false
            }
    
            if(data.password.length<6){
                setIsLoading(false)
                toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
                return false
            }
    
            if(data.repPassword.length<6){
                setIsLoading(false)
                toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
                return false
            }
    
            if(data.password!==data.repPassword){
                setIsLoading(false)
                toast.error("Las contraseñas deben ser iguales", {position: toast.POSITION.TOP_RIGHT})
                return false
            }
    
            let body = { 
                passwordCurrent: md5(data?.passwordCurrent),
                password: md5(data?.password)
            }
            const response = await dispatch(resetPasswordProfile(body))
            setIsLoading(false)
            
            if(response.status){
                reset({
                    passwordCurrent: '',
                    password: '',
                    repPassword: ''
                  })
                toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})				
            }else{
                toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            }
        }catch(error){
			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}        
    }

    const formElement = {
        register, 
        handleSubmit,
        errors,
        onSubmit        
    }

    const state = {
        isLoading
    }


    return {
        formElement,
        state
    }
}