import dayjs from 'dayjs';

export const getFechaFormat = (fecha) => {

	let mes = fecha.getMonth() + 1
	
	if(mes<10) {
		mes = `0${(fecha.getMonth() + 1)}`
	}
	
	let dia = fecha.getDate() 
	if(dia<10) {
		dia = `0${(fecha.getDate() )}`
	}

	let fecha_result = fecha.getFullYear() + '-' + mes + '-' + dia
	
    return fecha_result
}

export const formatFechaHora = (fecha) => {

	const fechaSearch = new Date(fecha).toLocaleString('es-CL', { timeZone: 'America/Santiago' })
	//fechaSearch.setMinutes(fechaSearch.getMinutes() + fechaSearch.getTimezoneOffset())
    return fechaSearch
}


export const formatHora = (fecha) => {

	const fechaSearch = new Date(fecha)//.toLocaleString('es-CL', { timeZone: 'America/Santiago' })
	fechaSearch.setMinutes(fechaSearch.getMinutes() + fechaSearch.getTimezoneOffset())
    return fechaSearch
}

export const isBefore = (date, compareDate) =>{
	const datejs = dayjs(date);
	const limitDate = dayjs(compareDate);

	return (datejs.isBefore(limitDate))  ? true : false;
};

export const isSame = (date, compareDate) =>{
	const datejs = dayjs(date);
	const limitDate = dayjs(compareDate);
	
	return (datejs.isSame(limitDate))  ? true : false;
};


export const isAfter = (date, compareDate) =>{

	const datejs = dayjs(date);
	const limitDate = dayjs(compareDate);

	return (datejs.isAfter(limitDate))  ? true : false;
};

export const getDateYMD = (value) =>{
	let message = ""
	let dateSelected = ""
	let error = false

	if(value===""){
		return { error: true, message: "La fecha ingresada es inválida", dateSelected: ""}
	}

	const dateSplit = value.split('/')

	if(dateSplit.length!==3){
		return { error: true, message: "La fecha ingresada es inválida", dateSelected: ""}
	}else if(dateSplit[2]==="" || dateSplit[1]==="" || dateSplit[0]===""){
		return { error: true, message: "La fecha ingresada es inválida", dateSelected: ""}
	}

	dateSelected = dayjs(dateSplit[2]+'-'+dateSplit[1]+'-'+dateSplit[0]).format('YYYY-MM-DD')

	if (dayjs(dateSelected).format('YYYY-MM-DD') === 'Invalid Date'){
		return { error: true, message: "La fecha ingresada es inválida", dateSelected: ""}
	}

	return { message, dateSelected, error };
};