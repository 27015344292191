import styles from "styles/Retiro.module.css";

const OrdenItem = ({  titleMain, valueMain, titleSecondary, valueSecondary, conversation_id, classBorder, classSend, viewClaim, is_notification }) => {
	
	return (
		<section className={`${styles["option-container"]} ${styles[typeof classBorder!=="undefined" ? classBorder : ""]}`}>
			<div className="w-100">
				<div className={styles["option-item"]}>
					<div className={styles["claim-title-container"]}>
					<p className={`${styles["item-text__title-fact"]} ${styles["item-text__title-claim"]}`}>{titleMain}</p> {is_notification===1 && <span className={`badge badge-danger ${styles["claim-badge"]}`}>1 Respuesta</span>} 
					</div>
					<p 
                        className={`${styles["option-details__view"]} ${styles[typeof classSend!=="undefined" ? classSend : ""]}`}
                        onClick={()=>viewClaim(conversation_id, valueSecondary, is_notification)}
                    >{valueMain}</p>
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>{titleSecondary}</p>
					<p className={styles["item-text__subtitle-fact"]}>{valueSecondary}</p>
				</div>
			</div>
		</section>
	);
};

export default OrdenItem;