import styles from "styles/Retiro.module.css";
import { useMultipleDestination } from "hooks/WithDrawals/useMultipleDestination"
import { ProgressBar } from 'react-bootstrap'


const FormMultipleDestino = ({ toast }) => {

	const {  infoDestination, sendFormCompleted, lastFormPreOrden, loading, viewList, configFormData } = useMultipleDestination(toast)


	return (
		<section className="row mt-4">
			
			{
				viewList && (
					<>
						<div className="col-12 mb-3">
							<p className={`${styles["text-template"]}`}>Verifique que los datos que se encuentra en el listado sea lo que subio en la plantilla, una vez finalizado haga clic en el botón Crear Retiro</p>
						</div>
						<div className="col-12 text-right mb-5">
							<button 
								type="button"
								className="btn btn-secondary btn-size-33 mt-0 pl-5 pr-5" 
								onClick={()=>lastFormPreOrden("downloadTemplate")}
							>
								Agregar otro envío
							</button>
						</div>
						<div className="col-12">
						<div className="table-responsive" style={{ maxHeight: '500px'}}>
							<table className={`table table-striped table-sm table-bordered ${styles["table-step"]}`}>
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Nombre</th>
										<th scope="col">Rut</th>
										<th scope="col">Dirección destino</th>
										<th scope="col">Número de contacto</th>
										<th scope="col">Correo</th>
										<th scope="col">Cant paquetes</th>
										<th scope="col">Peso</th>
										<th scope="col">Ciudad</th>
										<th scope="col">Comuna</th>
										<th scope="col">Forma de pago</th>
									</tr>
								</thead>
								<tbody>
									{
										infoDestination.length > 0 && 
										infoDestination.map((item, index) =>
											<tr key={item.id}>
												<th scope="row">{index+1}</th>
												<td>{item.nombre}</td>
												<td>{item.rut}</td>
												<td>{item.direccion_destino}</td>
												<td>{item.numero_de_contacto}</td>
												<td>{item.correo}</td>
												<td>{item.cantidad_de_paquetes}</td>
												<td>{item.peso}</td>
												<td>{item.sucursal_name}</td>
												<td>{item.comuna_name}</td>
												<td>{item.forma_pago}</td>
											</tr> 
										)
									}
								</tbody>
							</table>
						</div>
						</div>
					</>
				)
			}

			{
				!viewList && (
					<div className="col-10 mx-auto">
						<div className="col-12 mb-3">
							<p className={`${styles["text-template"]}`}>Progreso de la carga másiva de sus retiros</p>
						</div>
						<table className={`table table-borderless ${styles["table-font"]}`}>
							<thead>
								<tr>
								<th scope="col">Archivo</th>
								<th scope="col">Sucursal</th>
								<th scope="col">Comuna</th>
								<th scope="col">Cantidad</th>
								<th scope="col">Progreso</th>
								</tr>
							</thead>
							<tbody>
								{
									configFormData?.length > 0 && 
									configFormData?.map((item, index) =>
										<tr key={item.id}>
											<th scope="row">{item.path}</th>
											<td>{item.sucursal_name}</td>
											<td>{item.comuna_name}</td>
											<td>{`${item?.total ?? 0}/${item.cant}`}</td>
											<td><ProgressBar variant="success" animated now={item?.total===item.cant ? 100 : 45} /></td>
										</tr> 
									)
								}
							</tbody>
						</table>
					</div>
				)
			}		
			
			{
				loading && (
					<div className="col-12">
						<p className={`${styles["subtitle-completed"]} mb-3 mt-5 pt-5`}>Espere unos minutos, mientras se procesa sus archivos</p>
					</div>
				)
			}
			<div className="col-12">
				<div className={`${styles["btn-modal"]} ${styles["btn-options-modal"]}`}>
						<button 
							className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
							onClick={()=>lastFormPreOrden("downloadTemplate")}
						>
						Atrás
					</button>
					<button 
						type="button"
						className="btn btn-secondary btn-size-33 btn-block mt-0" 
						onClick={(event)=>sendFormCompleted(event)}
						disabled={loading}
					>
						{loading ? 'Procesando...' : 'Crear Retiro'} 
					</button>
				</div>
			</div>
		</section>
	);
};

export default FormMultipleDestino;