import { useState } from "react"
import { getInfoPackage, saveInfoPackage, downloadInformePDF, getDataTicketRetiroPreOrden } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'

export const usePackageInfo = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const { infoPackage, loadingInfoPackage } = useSelector((state) => state.preorden)
	const [ showTicket, setShowTicket ] = useState(false)
	const [ data, setData ] = useState([])
	const { register, handleSubmit, errors } = useForm()
	const [ show, setShow ] = useState(false)


	const onSubmit =  async (data, event) => {
		try{
		
           const response = await dispatch(getInfoPackage(data?.search_orden_flete))
		   if(!response.status){
				toast.error("El número de retiro no se encuentra registrado", {position: toast.POSITION.TOP_RIGHT})
			}
	
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const searchRUTHeader =  async (numero_retiro) => {
		try{

           let response = await dispatch(getInfoPackage(numero_retiro))

			if(response.status){
				history.push(`/retiro/${numero_retiro}/informe`)
			}else{
				toast.error("El número de retiro no está registrado", {position: toast.POSITION.TOP_RIGHT})
			}
		}catch(error){
			toast.error("El número de retiro no está registrado", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleCloseTicket = () => {
		setShowTicket(!showTicket)
	}

	const getInfoInforme = async (info, numero_retiro) => {
	
		if(typeof numero_retiro !=="undefined" && numero_retiro!==""){
			if(typeof info !=="undefined" && Object.keys(info).length > 0){
				dispatch(saveInfoPackage(info))
			}else{
				await dispatch(getInfoPackage(numero_retiro))
			}
		}		
	}

	const downloadInform = (event, numero_retiro) => {
		event.preventDefault()
		dispatch(downloadInformePDF({ numero_retiro }, "inform-retiro.pdf"))
	}

	const  getDataRetiro = async (printNroRetiro) => {
		let response = await dispatch(getDataTicketRetiroPreOrden(printNroRetiro, 1))
		setData(data => response?.data)
	}
	
	const handleClose = () => {
		setShow(!show)
	}

    const formElement = {
        onSubmit,
		register, 
		handleSubmit, 
		errors,
    }

    const state = {
        infoPackage,
        loadingInfoPackage,
		showTicket,
		data,
		show
    }

	const actions = {
		handleCloseTicket,
		getInfoInforme,
		downloadInform,
		searchRUTHeader,
		getDataRetiro,
		handleClose
	}
	

	return {
		formElement,
        state,
		actions

	}
}