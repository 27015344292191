import API from 'services/api/index';
import { 
	LIST_FORMA_PAGO,
} from 'services/types/formaPagoType';
import endPoints from 'services/routes/';


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getDataInitialPago = () => async (dispatch, getState) => {
	try{
		let response = await API.get(endPoints.formaPago.list);

        const { userData } = getState().users

        let listUpdatePayment = response?.data?.forma_pago?.filter(item => item?.descripcion!=="Contado");
        
        if(userData?.typeClient===null || userData?.typeClient===""){
            listUpdatePayment = listUpdatePayment.filter(item => item?.descripcion!=="Cuenta Corriente")
        }
		const payload = listUpdatePayment;
        dispatch(requestSuccess(LIST_FORMA_PAGO, payload));	
		
    }catch(error){

      return {status: false, message:error}
    }
};