import { 
	LIST_QUESTION,
	CHANGE_MENU_OPTIONS_QUESTION
} from 'services/types/questionType';
import { questions } from "data/dataQuestion.json"

const INITIAL_STATE = {
	listQuestionByFrequent: { type: "Preguntas frecuentes", list: questions},
	listQuestionByTransport: { type: "Preguntas sobre transporte", list: []},
	listQuestionByPackage: { type: "Preguntas sobre paquetes", list: []},
	listQuestionByPayments: { type: "Preguntas sobre pagos", list: []},
	listQuestionByDefects: { type: "Preguntas sobre defectos", list: []},
	listQuestionByLoss: { type: "Preguntas sobre pérdidas", list: []},
	listQuestionSelected: { name: "Preguntas frecuentes", list: questions}
};

const questionReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_QUESTION:
			return {
				...state,
				listQuestionByFrequent: action.payload.listQuestionByFrequent,
				listQuestionByTransport: action.payload.listQuestionByTransport,
				listQuestionByPackage: action.payload.listQuestionByPackage,
				listQuestionByPayments: action.payload.listQuestionByPayments,
				listQuestionByDefects: action.payload.listQuestionByDefects,
				listQuestionByLoss: action.payload.listQuestionByLoss
			}
		case CHANGE_MENU_OPTIONS_QUESTION:
			return {
				...state,
				listQuestionSelected: action.payload.selected,
				menuQuestion: action.payload.menuOptionsList
			}
		default: return state;
	}
};

export default questionReducer;