import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import CardAccordion from 'components/Custom/Accordion/CardAccordion';
import BodyAccordion from 'components/ClaimAndSearch/BodyAccordion';

const AccordionClaim = ({ listFeatures }) => {

	const [ activeKey, setActiveKey ] = useState('0')

	return (
		<Accordion activeKey={activeKey} onSelect={(event) => setActiveKey(event)}>
			{
				listFeatures.map((info, key) => (
					 <CardAccordion
					  		title={info.title}
					  		key={key}
					  		index={key}
					  		activeKey={activeKey}
					 	>
					 	<div className="details-car">
							{
								info.description.map((description, index) => (
									<BodyAccordion key={index} description={description} />
								))
							}						 
				      	</div>	
					</CardAccordion>
				))
			}
		</Accordion>
	)
}

export default AccordionClaim