import LoadingDetailOrden from "components/Withdrawals/Detail/LoadingDetailOrden";
import ContainerDetailOrden from "components/Withdrawals/Detail/ContainerDetailOrden";
import FormPreview from "components/Withdrawals/Detail/FormPreview";
import search from "assets/images/search.png";
import styles from "styles/Retiro.module.css";
import file_search from "assets/images/file-search.svg";
import { usePackageInfo } from "hooks/WithDrawals/usePackageInfo";
import { useLocation } from 'react-router-dom';
import { onPaste, validateOnlyNumber } from "utils/validationInput";


const InfoDetail = ({  isVisibleButton }) => {

	const { formElement, state, actions } = usePackageInfo()
	const location = useLocation()

	const { onSubmit, register, handleSubmit, errors } = formElement
	const { infoPackage, loadingInfoPackage, data, show } = state
	const { downloadInform, handleClose } = actions
	
	
	return (
		<>
			<div className={styles["group-item__container"]}>
				{
					!location?.pathname?.includes("/informe") && (
						<div className={`${styles["group-item"]} ${styles["detail-item__container"]}`}>
							<p className={`${styles["group-title"]} ${styles["group-title__notification"]}`}>Buscar un retiro</p>
						</div>
					)
				}			
				<section className="pl-4 pr-md-4 pr-lg-0">
					{
						!location?.pathname?.includes("/informe") && (
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className={`${styles["detail-item__input"]} ${styles["detail-item__input"]}`}>
									<input 
										type="text"
										placeholder="Buscar por número de retiro"
										id="search_orden_flete"
										name="search_orden_flete"
										className={styles["input-search"]}
										ref={register({required: {value: true, message: 'Requerido'}})}
										onPaste={(event) => onPaste(event)}				
										onKeyPress={(event) => validateOnlyNumber(event)}
										maxLength={20}
									/>
									<button className={styles["detail-item__search"]}>
										<img src={search} alt="Buscar" width={14} heigth={14} />
									</button>
								</div>
								{errors.search_orden_flete && (
									<p className="text-danger mb-4 w-100 text-left">
										{errors?.search_orden_flete?.message}
									</p>
								)}
							</form>
						)
					}			
					
					{
						(!loadingInfoPackage && infoPackage?.numero_retiro==="") && (
							<div className={styles["search-icon-img"]} >
								<img src={file_search} alt="Buscando..." />
								<p className={styles["item-text__subtitle-fact"]} >
									{infoPackage?.search ? "El número de retiro no se encuentra registrado" : ""} 
								</p>
							</div>	
						)
					}
					{
						loadingInfoPackage && (
							<LoadingDetailOrden infoPackage={infoPackage} />	
						)
					}
					{
						(!loadingInfoPackage && infoPackage?.numero_retiro!=="") && (
							<ContainerDetailOrden 
								infoPackage={infoPackage}  
								isVisibleButton={isVisibleButton} 
								downloadInform={downloadInform}
								data={data}
								handleClose={handleClose}
							/>	
						)
					}
				</section>
			</div>
			{
				show && 
				<FormPreview 
					printNroRetiro={infoPackage?.numero_retiro} 
					show={show}
					handleClose={handleClose}
				/>
			}

			
			
		</>
	);
};

export default InfoDetail;