import Layout from "components/Layout/Layout";
import ContainerOptions from "components/Custom/ContainerOptions"
import PanelInformationMob from "components/Custom/PanelInformationMob";
import PanelInformationDsk from "components/Custom/PanelInformationDsk"
import TableInvoice from "components/Withdrawals/TableInvoice";

import styles from "styles/Retiro.module.css";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import { useDashboard } from "hooks/Dashboard/useDashboard"
import MediaQuery from 'react-responsive'

const Dashboard = () => {

	const { optionsDashboardMenu, isLoadingDashboard, listGeneral, changeOptions, downloadFile } = useDashboard("dashboard")
	const { openModal, show  } = useModalWithDrawal(null)


	return (
		<Layout
			modulo="inicio"
			openModal={openModal}
			show={show}
		>

			<MediaQuery maxWidth={767}>
				<section className={styles["row-top"]}>
						<ContainerOptions
							title={listGeneral?.title}
							subtitle={listGeneral?.subtitle}
							menuOptionsList={optionsDashboardMenu}
							changeOptions={changeOptions}
							isInputSearch={true}
							isLoadingDashboard={isLoadingDashboard}
							openModal={openModal}
						/>
				</section>
				<div className="row">
					<div className="col-sm-12">
						<section className="d-flex">
							<section className={styles["group-container"]}>
								<div className="d-flex mb-5">
									{Object.keys(listGeneral).length>0 && (
										<PanelInformationMob 
											key={1} 
											title="" 
											listOrden={listGeneral}
											subtitle=""
											optClassName=""
											isVisibleHeader={false}
											isVisibleTitle={false}
											downloadFile={downloadFile}
											isLoadingDashboard={isLoadingDashboard}
										/>
									)}
								</div>
							</section>
						</section>
					</div>
				</div>
			</MediaQuery>

			<MediaQuery minWidth={767}>
				<div className="row">
					<div className="col-md-12 col-lg-8">
							<div className="row">
								<div className="col-md-12 col-lg-6">
									<PanelInformationDsk 
										key={2} 
										title={optionsDashboardMenu[0]?.name} 
										listOrden={optionsDashboardMenu[0]}
										subtitle="Ver todos"
										optClassName=""
										isVisibleHeader={true}
										isVisibleTitle={false}
										isVisisbleAll={true}
										isLoadingDashboard={optionsDashboardMenu[0]?.isLoading}
										url="/mis-retiros/busqueda"
									/>
								</div>
								<div className="col-md-12 col-lg-6">
									<PanelInformationDsk 
										key={3} 
										title={optionsDashboardMenu[1]?.name} 
										listOrden={optionsDashboardMenu[1]}
										subtitle="Ver todos"
										optClassName=""
										isVisibleHeader={true}
										isVisibleTitle={false}
										isVisisbleAll={true}
										isLoadingDashboard={optionsDashboardMenu[1]?.isLoading}
										url="/mis-ordenes/busqueda"
									/>
								</div>

								<div className="col-md-12 mt-5">
									<TableInvoice 
										title="Mis facturas"
										subtitle="Ver todas las facturas"
										listInvoice={optionsDashboardMenu[4]?.listDsk}
										downloadFile={downloadFile}
										isLoadingDashboard={optionsDashboardMenu[4]?.isLoading}
									/>
									
								</div>
							</div>
						
					</div>
					<div className="col-md-12 col-lg-4">
						<div className="row">
							<div className="col-md-12 col-lg-12">
								<PanelInformationDsk 
									key={4} 
									title={optionsDashboardMenu[3]?.name} 
									listOrden={optionsDashboardMenu[3]}
									subtitle=""
									optClassName=""
									isVisibleHeader={true}
									isVisibleTitle={false}
									isVisisbleAll={false}
									isLoadingDashboard={optionsDashboardMenu[3]?.isLoading}
									url=""
								/>
							</div>
							<div className="col-md-12 col-lg-12 mt-5">
								<PanelInformationDsk 
									key={4} 
									title={optionsDashboardMenu[2]?.name} 
									listOrden={optionsDashboardMenu[2]}
									subtitle=""
									optClassName=""
									isVisibleHeader={true}
									isVisibleTitle={false}
									isVisisbleAll={false}
									isLoadingDashboard={optionsDashboardMenu[2]?.isLoading}
									url=""
								/>
							</div>
						</div>
					</div>
				</div>
			</MediaQuery>

		</Layout>
	);

};

export default Dashboard;
