import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Login from "../presentacions/Login"
import Register from "presentacions/Register"
import HomePassword from "presentacions/HomePassword"
import Dashboard from "../presentacions/Dashboard"
import MyWithDrawals from "../presentacions/Retiros/"
import MyOficce from "../presentacions/Oficinas"
import Reclamos from "../presentacions/Reclamos/"
import Preguntas from "../presentacions/Preguntas/"
import ListadoRetiro from "../presentacions/Retiros/ListadoRetiro"
import ListadoOrders from "../presentacions/Ordenes/ListadoOrders"
import Profile from "../presentacions/Profile";
import Informe from "../presentacions/Informe";
import BuscarOrden from "../presentacions/Ordenes/BuscarOrden"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/normalize.css';
import '../styles/menu.css';
import '../styles/globals.css';
  
function App() {

  const verifyLogged = (Component) => {
    return localStorage.getItem("user")!==null && JSON.parse(atob(localStorage.getItem("user"))) ? <Component /> : <Redirect to="/" />
  }
  
    return (
      <Router>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/registrarse" component={Register} />
            <Route exact path="/mi-cuenta/password" component={HomePassword} />

            <Route exact path="/perfil" render={() => verifyLogged(Profile)} />
            
            <Route exact path="/paquetes" render={() => verifyLogged(Dashboard)} />
            
            <Route exact path="/mis-retiros/busqueda" render={() => verifyLogged(ListadoRetiro)} />
            <Route exact path="/mis-ordenes/busqueda" render={() => verifyLogged(ListadoOrders)} />
            
            <Route exact path="/mis-retiros" render={() => verifyLogged(MyWithDrawals)} />
            <Route exact path="/oficinas" render={() => verifyLogged(MyOficce)} />
            <Route exact path="/reclamos-y-consultas" render={() => verifyLogged(Reclamos)} />
            <Route exact path="/preguntas" render={() => verifyLogged(Preguntas)} />
            <Route exact path="/retiro/:numero_retiro/informe" render={() => verifyLogged(Informe)} />
            <Route exact path="/orden-flete/:numero_orden" render={() => verifyLogged(BuscarOrden)} />
        </Switch>
      </Router>
    );
  }
  
  export default App;
  