import { Modal } from 'react-bootstrap';
import FormClaim from "components/ClaimAndSearch/Form/FormClaim";
import FormCompleted from "components/ClaimAndSearch/Form/FormCompleted";
import close_btn from "assets/images/close.png";
import styles from "styles/Retiro.module.css";

const ModalClaimSearch = ({ toast, show, handleClose, modalName }) => {

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
            handleClose();
        }
    };

	return (
		<Modal 
			show={show} 
			onHide={handleClose} 
			className={styles["modal-withdrawals"]} 
			centered
			>
	        <Modal.Header className={styles["header-modal"]}>
	          <Modal.Title className={styles["title-modal"]}>{modalName.title}</Modal.Title>
	          <div aria-hidden="true" className={styles["btn-close"]} onClick={()=>handleClose()} onKeyDown={(e) =>handleKeyDown(e)}>
	          	<img src={close_btn} alt="Cerrar" width={12} heigth={12} className="modal-close" />
	          </div>
	        </Modal.Header>
	        <Modal.Body>
	        	<section className={styles["form-withdrawals"]}>
				  {modalName?.form==="frm-initial" && 
				  	<FormClaim 
						toast={toast} 
						handleCloseOpen={handleClose} 
						title="Motivo del reclamo / consulta"
						subtitle="Detalle del reclamo / consulta"
						type="modal"
					/> 
				  }
				  {modalName?.form==="frm-completed" && <FormCompleted />}
				</section>
	        </Modal.Body>
	    </Modal>
	)
}

export default ModalClaimSearch