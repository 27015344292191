import { useState } from "react";
import { validarRut } from "utils/ToolRUT/";

export const useUtil = (toast) => {

	const [ shown, setShown ] = useState(false)

	const handleBlurRut = (event, setError) =>{
		if(event.target.value!==""){
			const response = validarRut(event.target.value)
			if(response?.status===false){
				setError("rut", { type: "manual", message: response?.message})
			}else{
				setError("rut", { type: "manual", message: ""})
			}
		}		
	}

    const handleChangeEmail = (event, value, setError, setValue, name="email") => {
		let email = event!==null ? event.target.value : value

		if(email!==""){
			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(email)

			if(!isValidEmail){
				setValue(name, "")
				setError(name, { type: "manual", message: `El e-mail es inválido`})
			}else {
				setError(name, { type: "manual", message: ""})
			}
		}	
	}

	const handleChangePhoneNumber = (event, value, setError, setValue, name="telephone") => {
		let phone = event!==null ? event.target.value : value

		if(phone!==""){
			if(phone.length===9){
				let regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
				const isValidTelephone = regex.test("+56"+phone)
				if(!isValidTelephone){
					setValue(name, "")
					setError(name, { type: "manual", message: `El teléfono es inválido`})
				}else {
					setError(name, { type: "manual", message: ""})
				}
			}else{
				setError(name, { type: "manual", message: `El número ingresado es incorrecto`})
			}
		}		
	}

	const switchShown = () => setShown(!shown)


	return {
		handleBlurRut,
        handleChangeEmail,
		switchShown,
		shown,
		handleChangePhoneNumber
	}
}