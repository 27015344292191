import { useEffect } from "react"
import { getDataInitial, getComunaSelected, getSucursalSelected, getUrlMapa } from "services/actions/officeAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

export const useOffice = (toast) => {

	const dispatch = useDispatch()
	const { sucursales, sucursalSelected, comunaSelected, loadingComuna, urlMap } = useSelector((state) => state.offices)
	const { register, control } = useForm()

	const getInitial = async () => {
		await dispatch(getDataInitial(1))
	}


	const changeOffice = (event) => {
		let { value } = event.target
		if(value!==""){
			dispatch(getComunaSelected(value))
		}
	}

	const changeSucursal = (event) => {
		let { value } = event.target
		if(value!==""){
			dispatch(getSucursalSelected(value))
		}
	}
	
	const handleViewMapa = (event, url) => {
		event.preventDefault()
		dispatch(getUrlMapa(url))
	}
	

	useEffect(() => {
		getInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const state = { sucursales, sucursalSelected, comunaSelected, loadingComuna, urlMap }

	const formElement = {
		register, 
		control
	}

	const actions = {
		changeOffice,
		changeSucursal,
		handleViewMapa
	}

	return {
		formElement,
		state,
		actions
	}
}