import { useEffect } from "react"
import { changeItemMenuOptions, getInvoiceByClient, 
	loadingDashboard, getPackageSendCount, getMyPackagesList, getMyOrdersList,
	getMyNotification } from "services/actions/withdrawalAction"
import { getDataInitial } from "services/actions/officeAction"
import { getDataInitialPago } from "services/actions/formaPagoAction"
import { useDispatch, useSelector } from "react-redux"


export const useDashboard = () => {

	const dispatch = useDispatch()
	const { preorden, offices } = useSelector((state) => {
		return {
			preorden: state.preorden,
			offices: state.offices
		}
	})
	const { optionsDashboardMenu, isLoadingDashboard, listGeneral }  = preorden
	const { sucursales } = offices

	const getInitialDashboard = async () => {
		if(sucursales.length===0){
			dispatch(getDataInitial(1))
		}
		await dispatch(getDataInitialPago())
		if(optionsDashboardMenu[0].list.length===0 && optionsDashboardMenu[1].list.length===0){
			dispatch(loadingDashboard(true))
			await Promise.all([
		 		dispatch(getMyPackagesList(1, 7, null)),
				 dispatch(getMyOrdersList(1, 7, null)),
				 dispatch(getMyNotification(1, 7, null)),	
				 dispatch(getInvoiceByClient(1, 7)),
				 dispatch(getPackageSendCount())
			])
			
			dispatch(loadingDashboard(false))
		}
		
	}


	const changeOptions = (item) => {
		dispatch(changeItemMenuOptions(item))
	}

	const downloadFile = (event, url, number_invoice) => {
		event.preventDefault()

		let url_documento = url
		if(url.includes("http")){
			url_documento = url.replace("http", "https")
		}
		let a = document.createElement('a');
		a.href = url_documento;
		a.download = `documento_${number_invoice}.pdf`;
		a.click();
	}

	useEffect(() => {
		getInitialDashboard()		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		listGeneral,
		optionsDashboardMenu,
		isLoadingDashboard,
		changeOptions,
		downloadFile
	}
}