import React from 'react';

const BodyAccordion = ({ description }) => {

	return (
		<>
			{
				!description?.isList ? <p>{description.text}</p>
                : 
                <ul className="list-unstyled">
                    {description.list.length > 0 && description.list.map((item, key) => (
                        <React.Fragment key={key}>
                            <li>{item.title}</li>
                            {item.description!=="" && <p>{item.description}</p>} 
                        </React.Fragment>
                    ))}
                </ul>
                
			}
		</>
	)
}

export default BodyAccordion;