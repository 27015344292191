import Layout from "components/Layout/Layout";
import ContainerOptions from "components/Custom/ContainerOptions";
import PanelInformationMob from "components/Custom/PanelInformationMob";
import PanelInformationDsk from "components/Custom/PanelInformationDsk"
import TableInvoice from "components/Withdrawals/TableInvoice";
import InfoDetail from "components/Withdrawals/Detail/InfoDetail";
import ModalTicket from "components/Withdrawals/Detail/ModalTicket"
import styles from "styles/Retiro.module.css";
import { useDashboard } from "hooks/WithDrawals/useDashboard";
import { usePackageInfo } from "hooks/WithDrawals/usePackageInfo";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import MediaQuery from 'react-responsive'


const MisRetiros = () => {

	
	const { isLoadingRetiro, menuOptionsListRetiro, listGeneralRetiro, 
		changeOptionsRetiro, downloadFile } = useDashboard()
	
	const { state, actions } = usePackageInfo()
	const { showTicket, infoPackage } = state
	const { handleCloseTicket } = actions
	
	const { openModal, show  } = useModalWithDrawal(null)


	return (
		<Layout
			modulo="mis-retiros"
			openModal={openModal}
			show={show}
		>
			<MediaQuery maxWidth={767}>
				<section className={styles["row-top"]}>
						<ContainerOptions
							title={listGeneralRetiro?.title}
							subtitle={listGeneralRetiro?.subtitle}
							menuOptionsList={menuOptionsListRetiro}
							changeOptions={changeOptionsRetiro}
							isInputSearch={true}
							isLoadingDashboard={isLoadingRetiro}
							openModal={openModal}
						/>
				</section>
				<div className="row">
					<div className="col-sm-12">
						<section className="d-flex">
							<section className={styles["group-container"]}>
								<div className="d-flex mb-5">
									{Object.keys(listGeneralRetiro).length>0 && (
										<PanelInformationMob 
											key={1} 
											title="" 
											listOrden={listGeneralRetiro}
											subtitle=""
											optClassName=""
											isVisibleHeader={false}
											isVisibleTitle={false}
											isLoadingDashboard={isLoadingRetiro}
										/>
									)}
								</div>
							</section>
						</section>
					</div>
				</div>
			</MediaQuery>

			<MediaQuery minWidth={767}>
				<div className="row">
					<div className="col-md-12 col-lg-8">
							<div className="row">
								<div className="col-md-12 col-lg-6">
									<PanelInformationDsk 
										key={2} 
										title={menuOptionsListRetiro[0]?.name} 
										listOrden={menuOptionsListRetiro[0]}
										subtitle="Ver todos"
										optClassName=""
										isVisibleHeader={true}
										isVisibleTitle={false}
										isVisisbleAll={true}
										isLoadingDashboard={isLoadingRetiro}
										url="/mis-retiros/busqueda"
									/>
								</div>
								<div className="col-md-12 col-lg-6">
									<PanelInformationDsk 
										key={3} 
										title={menuOptionsListRetiro[1]?.name} 
										listOrden={menuOptionsListRetiro[1]}
										subtitle="Ver todos"
										optClassName=""
										isVisibleHeader={true}
										isVisibleTitle={false}
										isVisisbleAll={true}
										isLoadingDashboard={isLoadingRetiro}
										url="/mis-ordenes/busqueda"
									/>
								</div>
								<div className="col-md-12 mt-5">
									<TableInvoice 
										title="Mis facturas"
										subtitle="Ver todas las facturas"
										listInvoice={menuOptionsListRetiro[2]?.listDsk}
										downloadFile={downloadFile}
										isLoadingDashboard={isLoadingRetiro}
									/>
								</div>
							</div>
					</div>
					<div className="col-md-12 col-lg-4">
					{
						(!isLoadingRetiro && menuOptionsListRetiro.length>0) && (
							<div className="row">
								<div className="col-md-12 col-lg-12 mt-md-5 mt-lg-0">
									<InfoDetail isVisibleButton={true} />
								</div>
							</div>
						)
					}
					</div>
				</div>
			</MediaQuery>
			
			<ModalTicket 
				show={showTicket}
				handleClose={handleCloseTicket}
				infoPackage={infoPackage}
			/>
		</Layout>
	);

};

export default MisRetiros;