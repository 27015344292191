import BaseLayoutLogin from "components/Layout/BaseLayoutLogin";
import IconInputPassword from "components/Password/IconInputPassword";
import delivery_mobile from "assets/images/delivery-mobile.svg";
import circle_success from "assets/images/circle-success.svg";
import logo from "assets/images/logo.svg";
import styles from "styles/Login.module.css";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify'
import { useRegister } from "hooks/Login/useRegister"
import { useUtil } from "hooks/Utils/useUtil"
import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'


export default function Register () {

	const { state, formElement, actions } = useRegister(toast)
	const { handleBlurRut, handleChangeEmail, handleChangePhoneNumber, 
		switchShown: switchShownPass, shown:shownPass } = useUtil(toast)
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil(toast)

	const { onSubmit, register, handleSubmit, errors, control, setError, setValue } = formElement
	const { isLoading, isSuccess, isDisabled } = state
	const { setIsDisabled } = actions

	return (
		<BaseLayoutLogin>
			
			<form className={styles["form-login"]} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						
				<div className="got-back" >
					<Link to="/" className="got-back__link">Volver</Link>
				</div>
				<div className={`${styles["group-image__logo"]} mb-5`}>
					<img src={logo} alt="logo" width={147} height={96} />
				</div>
				<div className={styles["group-image"]}>
					<img src={delivery_mobile} alt="Login" width={230} height={128} />
				</div>
				<input type="email" name="email" value="something@gmail.com" className="label-hidden"/>
				{ !isSuccess && (
					<section className={styles["form-section"]}>
					<div className={`
						form-group 
						${(Object.keys(errors).length>0 && typeof errors?.fullname?.message!=="undefined" && errors?.fullname?.message!=="") ? 
						"mb-1" : "mb-4"}`}
					>
					    <input 
					    	type="text" 
					    	className={`form-control ${styles["form-input"]}`}
					    	id="fullname" 
					    	name="fullname"
					    	aria-describedby="fullname"
					    	placeholder="Nombre y Apellido"
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    />
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.fullname?.message!=="undefined" && errors?.fullname?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.fullname?.message}
				    	</p>
				    )}
					 <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.rut?.message!=="undefined" && errors?.rut?.message!=="") ? "mb-1" : "mb-4"}`}>
						<Controller
							render={(props) => (
								<MaskedInput
									mask={RutTextMask}
									className={`form-control ${styles["form-input"]}`} 
									placeholder="Ej: 12.345.678-9"
									id="rut" 
									name="rut"
									value={props.value}
									onChange={(value)=>{
										props.onChange(value)
									}}
									onBlur={(event) => handleBlurRut(event, setError)}
								/>
							)}
							control={control}
							name="rut"  
							rules={{required: {value: true, message: 'Requerido'}}}
						/>
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.rut?.message!=="undefined" && errors?.rut?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.rut?.message}
				    	</p>
				    )}
					
				    <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.telephone?.message!=="undefined" && errors?.telephone?.message!=="") ? "mb-1" : "mb-4"}`}>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="telephone">+56</span>
							</div>
							<input 
								type="text" 
								className={`form-control ${styles["form-input"]}`}
								id="telephone" 
								name="telephone"
								aria-describedby="telephone"
								placeholder="Número de teléfono"
								ref={register({
									required: {value: true, message: 'Requerido'},
									minLength: {value: 9, message: 'Mínimo 9 dígitos'}
								})}
								onBlur={(event) => handleChangePhoneNumber(event, "", setError, setValue)}
								maxLength={9}
								pattern="[0-9]+"
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
									}
								}}
							/>
						</div>					
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.telephone?.message!=="undefined" && errors?.telephone?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.telephone?.message}
				    	</p>
				    )}
				    <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.r_email?.message!=="undefined" && errors?.r_email?.message!=="") ? "mb-1" : "mb-4"}`}>
						
						<input 
					    	type="email" 
					    	className={`form-control ${styles["form-input"]}`}
					    	id="r_email" 
					    	name="r_email"
							autoComplete="off"
					    	aria-describedby="r_email"
					    	placeholder="Correo electrónico"
					    	ref={register({
								required: {value: true, message: 'Requerido'},
								//eslint-disable-next-line
								pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
							})}
					    	onBlur={(event) => handleChangeEmail(event, "", setError, setValue, "r_email")}
							defaultValue=""
							
					    />
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.r_email?.message!=="undefined" && errors?.r_email?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.r_email?.message}
				    	</p>
				    )}
				    <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.password?.message!=="undefined" && errors?.password?.message!=="") ? "mb-1" : "mb-4"}`}>
					    <input 
					    	type={shownPass ? 'text' : 'password'} 
					    	className={`form-control ${styles["form-input"]}`}
					    	id="password" 
					    	name="password"
					    	aria-describedby="password"
					    	placeholder="Contraseña"
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    />
						<IconInputPassword 
							shown={shownPass}
							switchShown={switchShownPass}
						/>
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.password?.message!=="undefined" && errors?.password?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.password?.message}
				    	</p>
				    )}
				    <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.repPassword?.message!=="undefined" && errors?.repPassword?.message!=="") ? "mb-1" : "mb-0"}`}>
					    <input 
					    	type={shownRep ? 'text' : 'password'} 
					    	className={`form-control ${styles["form-input"]}`}
					    	id="repPassword" 
					    	name="repPassword"
					    	aria-describedby="repPassword"
					    	placeholder="Confirmar contraseña"
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    />
						<IconInputPassword 
							shown={shownRep}
							switchShown={switchShownRep}
						/>
					 </div>
					 {(Object.keys(errors).length>0 && typeof errors?.repPassword?.message!=="undefined" && errors?.repPassword?.message!=="")  && (
				    	<p className="text-danger mb-2 w-100 text-left">
				      		{errors?.repPassword?.message}
				    	</p>
				    )}
					 <div className={styles["group-check"]}>
					 	<div className={`form-group ${styles["form-group-check"]} mt-0`}>
					    	<input 
								type="checkbox" 
								className={styles["form-check"]} 
								id="recordarme" 
								onChange={() => setIsDisabled(!isDisabled)}
								name="recordarme" 
								defaultChecked={isDisabled}
							/>
					    	<label className={styles["form-check-label"]} htmlFor="recordarme">Estoy de acuerdo con los Terminos y Condiciones</label>
					  	</div>
					 </div>
					 <div className="form-group">
					    <button 
					    	type="submit" 
					    	className="btn btn-primary btn-block mb-4"
					    	disabled={isLoading || !isDisabled}
					    >{isLoading ? "Procesando..." : "Registrarse"}</button>
					 </div>
					</section>
				)}
				{ isSuccess && (
					<section>
						<div className={`form-group`}>
							<div className={styles["mx-auto"]}>
								<img src={circle_success} alt="Login" width={230} height={128} />
							</div>
							<p className={styles["text-account"]}>Se ha registrado <br/> exitosamente</p>
						</div>
						
						 <div className="form-group">
						    <Link to="/"
						    	className="btn btn-primary btn-block mb-4 pt-3"
						    >Iniciar sesión</Link>
						 </div>
					</section>
				)}
			</form>
		</BaseLayoutLogin>
	);
};