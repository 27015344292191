import { 
	LIST_FORMA_PAGO,
} from 'services/types/formaPagoType'


const INITIAL_STATE = {
	listFormaPagos: []
};

const formaPagoReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_FORMA_PAGO:
			return {
				...state,
				listFormaPagos: action.payload,
			}
		default: return state;
	}
};

export default formaPagoReducer;