import styles from "styles/Retiro.module.css";
import package_img from "assets/images/package.png";

const OrdenItemSend = ({ count, title }) => {

	return (
		<section className={`${styles["option-container"]} ${styles["border-ttracking"]}`}>
			<div className={styles["option-item"]}>
				<div className={styles["container-package"]}>
					<img src={package_img} alt="Paquetes" />
					<div className={styles["item-text__container"]}>
						<p className={`${styles["item-text__subtitle-fact"]} mb-0`}>Totales</p>
						<p className={`${styles["item-text__title-fact"]} mb-0`}>{title}</p>
					</div>
				</div>
				<div>
					<p className={styles["text-count"]}>{count}</p>
				</div>
			</div>
			
		</section>
	)
}

export default OrdenItemSend