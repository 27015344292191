import { Modal } from 'react-bootstrap';
import close_btn from "assets/images/close.png";
import styles from "styles/Retiro.module.css";
import { useRepleyClaim } from 'hooks/Claims/useRepleyClaim';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const ModalInfoClaim = ({ show, handleClose, claimSelected, title, loadingModal }) => {

	const { register, handleSubmit, errors, onSubmit, isLoading, isReply, updateReply } = useRepleyClaim();

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
            handleClose();
        }
    };

	return (
		<Modal 
			show={show} 
			onHide={handleClose} 
			className={styles["modal-withdrawals"]} 
			centered
			size="lg"
			>
	        <Modal.Header className={styles["header-modal"]}>
	          <Modal.Title className={styles["title-modal"]}>Asunto: {title}</Modal.Title>
	          <div aria-hidden="true" className={styles["btn-close"]} onClick={()=>handleClose()} onKeyDown={(e) =>handleKeyDown(e)}>
	          	<img src={close_btn} alt="Cerrar" width={12} heigth={12} className="modal-close" />
	          </div>
	        </Modal.Header>
	        <Modal.Body className={`p-5 ${styles["body-claim"]}`} >
				
	        	<section className={styles["form-withdrawals"]}>
					{
						loadingModal && Array.from([1, 2, 3]).map((item, key) => (
							<div className={styles["info-claim"]} key={key}>
							<div className={styles["info-claim__header"]}>
								<p className={styles["info-claim__title"]}><Skeleton width={200} height={20} count={1} /></p>
								<p className={styles["info-claim__subtitle"]}><Skeleton width={200} height={20} count={1} /></p>
							</div>
							<p className={styles["info-claim__subtitle"]}><Skeleton height={40} count={1} /></p>
						</div>
					))}
					
					{
						!loadingModal && claimSelected?.length>0 &&
						claimSelected?.map((item, key) => 
							<div className={styles["info-claim"]} key={key}>
								<div className={styles["info-claim__header"]}>
									<p className={styles["info-claim__title"]}>{item?.full_name}{item?.status===1 && <span className={`badge badge-danger ${styles["claim-badge"]}`}>Nuevo</span>}</p>
									<p className={styles["info-claim__subtitle"]}>{item?.date_text}</p>
								</div>
								<p className={styles["info-claim__subtitle"]}>{item?.body}</p>
								{
									(!isReply && key===claimSelected?.length-1) && (
										<button 
											type="button"
											className={`btn btn-primary ${styles["btn-reply"]} mt-3`} 
											onClick={()=>updateReply()}
										>
											Responder
										</button>
									)
								}
							</div>
						)
					}
				</section>
				{
					isReply && (
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={styles["info-claim"]}>
								<textarea 
									className="form-control" 
									id="message" 
									name="message"
									rows={4}
									cols={4}
									ref={register({required: {value: true, message: 'Requerido'}})}
									></textarea>
									{errors.message && (
										<p className="text-danger mb-4">
											{errors?.message?.message}
										</p>
									)}
								<div className={`text-right mt-4 ${styles["footer-modal-claim"]}`}>
									<button 
										type="button"
										className={`btn btn-outline-primary ${styles["btn-send"]} mr-4`} 
										onClick={()=>updateReply()}
									>
										Cancelar
									</button>
									<button 
										type="submit"
										className={`btn btn-secondary ${styles["btn-send"]}`} 
										disabled={isLoading}
									>
										{isLoading ? "Enviando..." : "Enviar" }
									</button>
								</div>
							</div>
						</form>
					)
				}
	        </Modal.Body>
	    </Modal>
	)
}

export default ModalInfoClaim