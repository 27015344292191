import { useState, useEffect } from "react"
import Layout from "components/Layout/Layout";
import AccordionPreOrden from "components/Informe/AccordionPreOrden";
import InfoDetail from "components/Withdrawals/Detail/InfoDetail";
import styles from "styles/Retiro.module.css";
import { useLocation, useParams } from "react-router-dom";
import { usePackageInfo } from "hooks/WithDrawals/usePackageInfo";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import { Link } from "react-router-dom"

const Informe = () => {

    const location = useLocation()
    const { numero_retiro } = useParams()
    const { state } = location
    const [ activeKey, setActiveKey ] = useState('0')
    const { state: statePackage, actions } = usePackageInfo()
    const { openModal, show  } = useModalWithDrawal(null)

    const { getInfoInforme } = actions
    const { infoPackage } = statePackage
    
    useEffect(() => {
        getInfoInforme(state, numero_retiro)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numero_retiro])

	return (
		<Layout
			modulo="inicio"
            openModal={openModal}
			show={show}
		>
                <div className="row mb-5">
                    <div className={`col-12 ${styles["d-form"]}`}>
                        <div className={`${styles["breadcrumb"]} ${styles["d-form"]}`}>
                            <Link to="/mis-retiros">
                                <button className={`btn ${styles["btn-atras"]}`}>Atrás</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <InfoDetail isVisibleButton={infoPackage?.preorden.length===0 ? true : false} />
                    </div>
                    <div className="col-8">
                        <section className={styles["group-container-items"]}>
                             <div className="table-responsive">  
                                <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className={styles["title-card"]} style={{width:"100px"}}>Fecha</th>
                                        <th scope="col" className={styles["title-card"]} style={{width:"50px"}}>Hora</th>
                                        <th scope="col" className={styles["title-card"]} style={{width:"150px"}}>Estado</th>
                                        <th scope="col" className={styles["title-card"]}>Observación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (Object.keys(infoPackage).length>0  && infoPackage?.numero_retiro!=="") &&
                                        infoPackage?.estados.map((item, key) =>
                                            <tr  key={key} className={styles["subtitle-card"]}>
                                                <td>{item?.fecha}</td>
                                                <td>{item?.hora}</td>
                                                <td>{item?.descripcion}</td>
                                                <td>{item?.observacio} </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        (Object.keys(infoPackage).length>0  && infoPackage?.preorden.length===0) && (
                                            <tr  className={styles["subtitle-card"]}>
                                                <td colSpan={4}>No hay registro disponible</td>
                                            </tr>
                                       ) 
                                    }
                                </tbody>
                                </table>
							</div>	
						</section>
                        <section className={styles["group-container-items"]}>
                            {
                                (Object.keys(infoPackage).length>0 && infoPackage?.numero_retiro!=="") && (
                                    <AccordionPreOrden 
                                        listFeatures={infoPackage?.preorden} 
                                        activeKey={activeKey} 
                                        setActiveKey={setActiveKey} 
                                    />
                                )
                            }						
								
						</section>
                    </div>
                </div>
		</Layout>
	);

};

export default Informe;
