import { Modal } from 'react-bootstrap';
import FormReceive from "components/Withdrawals/Forms/FormReceive";
import FormConfirmMultipleDestinatation from "components/Withdrawals/Forms/FormConfirmMultipleDestinatation";
import FormDestination from "components/Withdrawals/Forms/FormDestination";
import FormPackage from "components/Withdrawals/Forms/FormPackage";
import FormAditional from "components/Withdrawals/Forms/FormAditional";
import FormCompleted from "components/Withdrawals/Forms/FormCompleted";
import FormPreview from "components/Withdrawals/Forms/FormPreview";
import FormMultipleDestino from "components/Withdrawals/Forms/FormMultipleDestino";
import FormFile from "components/Withdrawals/Forms/FormFile"


import close_btn from "assets/images/close.png";
import styles from "styles/Retiro.module.css";
import { toast } from "react-toastify";

const ModalForm = ({ show, handleClose, nextForm, formName, sucursales, saveDestination, multiple_destinatation,
resetValudeDestination, listFormaPagos }) => {

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
            handleClose();
        }
    };

	return (
		<Modal 
			show={show} 
			onHide={handleClose} 
			className={styles["modal-withdrawals"]} 
			centered
			size={(formName?.form==="preview" || formName?.form==="downloadTemplate" || formName?.form==="receive" ||  formName?.form==="destination") ? "lg" : ""}
			backdrop="static"
        	keyboard={false}
			dialogClassName={formName?.form==="destinationMultiple" ? "modal-90w" : ""} 
		>
	        <Modal.Header className={styles["header-modal"]}>
	          <Modal.Title className={styles["title-modal"]}>{formName.title}</Modal.Title>
	          <div aria-hidden="true" className={styles["btn-close"]} onClick={()=>handleClose()} onKeyDown={(e) =>handleKeyDown(e)}>
	          	<img src={close_btn} alt="Cerrar" width={12} heigth={12} className="modal-close" />
	          </div>
	        </Modal.Header>
	        <Modal.Body className="show-grid">
	        	<div className={formName?.form==="destinationMultiple" ? "container-fluid" : "container"}>
				{formName?.form==="receive" && <FormReceive nextForm={nextForm} handleClose={handleClose} sucursales={sucursales} />}
				  { formName?.form==="confirmationMultipleDestination" && <FormConfirmMultipleDestinatation saveDestination={saveDestination} resetValudeDestination={resetValudeDestination} /> }
				  { formName?.form==="downloadTemplate" && <FormFile handleClose={handleClose} toast={toast} nextForm={nextForm} sucursales={sucursales} listFormaPagos={listFormaPagos} /> }

				  { formName?.form==="destinationMultiple" && <FormMultipleDestino handleClose={handleClose} toast={toast} sucursales={sucursales} />}
				  { formName?.form==="destination" && <FormDestination handleClose={handleClose} toast={toast} sucursales={sucursales} listFormaPagos={listFormaPagos} />}
				  { formName?.form==="package" && <FormPackage toast={toast} />}
				  { formName?.form==="aditional" && <FormAditional toast={toast} nextNameFrom={multiple_destinatation ? "destinationMultiple" : "package"} />}
				  { formName?.form==="completed" && <FormCompleted nextForm={nextForm} />}
					{ formName?.form==="preview" && <FormPreview />}
			
				</div>
	        </Modal.Body>
	    </Modal>
	);
};

export default ModalForm;