import { Controller } from "react-hook-form"

const FilterOffice = ({ sucursales, control, changeSucursal }) => {

	return (
        <form>
            <section className="row">
                <div className="col-12 col-md-6">
                <Controller
                            control={control}
                            name="sucursal_id"
                            render = {(props) => {
                                return (
                                <select 
                                    value={props.value}
                                    className="form-control mb-4"
                                    id="sucursal_id"   
                                    onChange={e => {                              
                                        props.onChange(e.target.value);
                                        changeSucursal(e)
                                    }}                         
                                >
                                    {
                                        sucursales.length>0 && sucursales.map((item, key) => 
                                            <option value={item.id} key={key}>{item.nombre}</option>
                                        )
                                    }
                                </select>
                            )}}
                            defaultValue={""}
                        />
                </div>
                
            </section>
        </form>
	)
}

export default FilterOffice