import { 
	LIST_OFFICE,
	SELECTED_COMUNA,
	LOADING_COMUNA,
	SELECTED_SUCURSAL,
	CHANGE_URL_MAPA
} from 'services/types/OfficeType';


const INITIAL_STATE = {
	sucursales: [],
	sucursalSelected: {},
	comunaSelected: {},
	loadingComuna: false,
	urlMap: ""
};

const OfficeReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_OFFICE:
			return {
				...state,
				sucursales: action.payload.sucursales,
				sucursalSelected: action.payload.sucursalSelected,
				comunaSelected: action.payload.comunaSelected,
				loadingComuna: action.payload.loadingComuna,
				urlMap: action.payload.urlMap
			}
		case SELECTED_COMUNA: 
			return {
				...state,
				comunaSelected: action.payload.comunaSelected,
				loadingComuna: action.payload.loadingComuna
			}
		case LOADING_COMUNA:
			return {
				...state,
				loadingComuna: action.payload
			}
		case SELECTED_SUCURSAL:
			return {
				...state,
				sucursalSelected: action.payload.sucursalSelected,
				comunaSelected: action.payload.comunaSelected,
				loadingComuna: action.payload.loadingComuna,
				urlMap: action.payload.urlMap
			}
		case CHANGE_URL_MAPA:
			return {
				...state,
				urlMap: action.payload.urlMapa
			}
		default: return state;
	}
};

export default OfficeReducer;