import { useState } from "react"
import Layout from "components/Layout/Layout";
import ContainerOptions from "components/Custom/ContainerOptions"
import AccordionClaim from "components/ClaimAndSearch/AccordionClaim";
import PanelQuestion from "components/ClaimAndSearch/PanelQuestion";

import styles from "styles/Retiro.module.css";
import { useQuestion } from "hooks/Question/useQuestion";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import MediaQuery from 'react-responsive'

const Preguntas = () => {

	const [ activeKey, setActiveKey ] = useState('0')
	const { listQuestionSelected, changeOptions } = useQuestion()
	const { openModal, show  } = useModalWithDrawal(null)

	return (
		<Layout
			modulo="mis-retiros"
			openModal={openModal}
			show={show}
		>
			<MediaQuery maxWidth={767}>
				<section className={styles["row-top"]}>
						<ContainerOptions
							title="Preguntas Frecuentes"
							subtitle="Puede ver todas las preguntas frecuentes relacionadas sobre encomiendas."
							menuOptionsList={[]}
							changeOptions={changeOptions}
							isInputSearch={false}
							isBtnRetiro={false}
						/>
				</section>
				<div className="row">
					<div className="col-sm-12 mb-5 pb-5">
						<section className="d-flex mb-5 pb-5">
							<section className={styles["group-container"]}>
								<AccordionClaim 
									listFeatures={listQuestionSelected?.list} 
									activeKey={activeKey} 
									setActiveKey={setActiveKey} 
								/>
								
							</section>
							
						</section>
					</div>
					
				</div>
			</MediaQuery>
			<MediaQuery minWidth={767}>
				<div className="row">
					<PanelQuestion 
						listQuestion={listQuestionSelected?.list}
					/>
				</div>
			</MediaQuery>
				

		</Layout>
	);

};

export default Preguntas;