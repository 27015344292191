export const onPasteOnlyPunto = (event) => {
    if(event.clipboardData.getData('text/plain').includes(".")){
      event.preventDefault();
      event.stopPropagation();
    }		
};

export const onPasteOnlyComa = (event) => {
    if(event.clipboardData.getData('text/plain').includes(",")){
      event.preventDefault();
      event.stopPropagation();
    }		
};

export const onPaste = (event) => {
    event.preventDefault();
    event.stopPropagation();	
};

export const onPasteOnlyPuntoAndComa = (event) => {
    if(event.clipboardData.getData('text/plain').includes(".") || event.clipboardData.getData('text/plain').includes(",")){
      event.preventDefault();
      event.stopPropagation();
    }		
  };

export const validateOnlyLetter = (event) => {
    if (!/[ñíóáéú a-zA-ZÑÍÓÁÉÚ ]/.test(event.key)) {
        event.preventDefault();
    }
}

export const validateOnlyLetterNumberG = (event) => {
  if (!/[A-ZÑÍÓÁÉÚ0-9- ]/.test(event.key)) {
      event.preventDefault();
  }
}

export const validateOnlyLetterNumber = (event) => {
  if (!/[ñíóáéú a-zA-ZÑÍÓÁÉÚ0-9 ]/.test(event.key)) {
      event.preventDefault();
  }
}

export const validateOnlyNumber= (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
}

export const validateOnlyRUT = (event) => {
    if (!/[0-9Kk.-]/.test(event.key)) {
        event.preventDefault();
      }
}

export const validateOnlyOrden = (event) => {
    if (!/[0-9E]/.test(event.key)) {
        event.preventDefault();
      }
}

export const validateOnlyOrdenPackage = (event) => {
  if (!/[0-9E-]/.test(event.key)) {
      event.preventDefault();
    }
}

export const validateOnlyComa = (event) => {
    if (!/[0-9,]/.test(event.key)) {
        event.preventDefault();
      }
}


const setPoint = (number) => {
  const stringNumber = String(number);
  return `${stringNumber.slice(0,1)}${'.'}${stringNumber.slice(1,4)}`;
};

export const formatNumberCurrency = (number) => {
  if (isNaN(Number(number))) {
      return String(number).length > 0 ? number : '0';
  }

  let numberFixed = Number(number);

  if (numberFixed > 999 && numberFixed < 10000) {
      return setPoint(numberFixed);
  }

  return Number(numberFixed).toLocaleString('es', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};

