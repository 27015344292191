import OrdenItem from "components/Withdrawals/OrdenItem";
import OrdenItemInvoice from "components/Withdrawals/OrdenItemInvoice";
import OrdenItemSend from "components/Withdrawals/OrdenItemSend";
import InfoDetail from "components/Withdrawals/Detail/InfoDetail";

import styles from "styles/Retiro.module.css";
import icon_inbox from "assets/images/icon-inbox.svg";

const ContainerWithdrawalsMob = ({  listOrden, downloadFile=null }) => {


    if((listOrden.name!=="Mis facturas" && listOrden.name!=="Estados de envíos" && listOrden.name!=="Buscar un retiro")) {
        return listOrden?.list.length>0 && listOrden?.list?.map((item, index) => 
               <OrdenItem key={index} {...item} />
           ) 
    }

    if(listOrden.name==="Buscar un retiro") {
        return <InfoDetail isVisibleButton={true} />
    } 

    if(listOrden.name==="Mis facturas") {

        return (
            <>
            {
                listOrden?.list?.length>0 ? (
                    <>
                        <section className="mt-5 mb-3">
                                <button 
                                  className="btn btn-outline-primary btn-size-33 mr-5 btn-block mb-3 font-10" 
                              >
                               Ver todas mis facturas
                            </button>
                        </section>
                        {listOrden?.list?.map((item, index) => 
                            <OrdenItemInvoice key={index} {...item} downloadFile={downloadFile} />
                        )}
                    </> 
                ) : (
                    <div className={styles["options-not-records"]}>
                        <img src={icon_inbox} alt="No hay registros" />
                        <p className={styles["item-text__subtitle-fact"]}>
                            No hay facturas disponibles
                        </p>
                    </div>
                )
            }
            </>
        )
    } 

    if(listOrden.name==="Estados de envíos" && listOrden?.list?.length>0) {

        return (
            <>
                {listOrden?.list?.map((item, index) => 
                    <OrdenItemSend key={index} {...item} />
                )}
                {/**<section className="mt-5 mb-3">
                        <button 
                        className="btn btn-outline-primary btn-size-33 mr-5 btn-block mb-3 font-10" 
                    >
                    Ver historial de envíos
                    </button>
                </section> */}
            </>
        )
    } 

    if(listOrden.name==="Estados de envíos" || listOrden.name==="Buscar un retiro") {

        return listOrden?.list.length === 0 ? null :
                <div className={styles["options-not-records"]}>
                    <img src={icon_inbox} alt="No hay registros" />
                    <p className={styles["item-text__subtitle-fact"]}>
                        {
                            listOrden?.name==="Mis retiros" ?
                            "No hay retiros disponibles" :
                            listOrden?.name==="Mis órdenes" ?
                            "No hay órdenes disponibles" :
                            listOrden?.name==="Notificaciones" ?
                            "No hay notificaciones disponibles" :
                            "No hay registros disponibles" 
                        }
                    </p>
                </div>
    }

    

    

   
};

export default ContainerWithdrawalsMob;