import styles from "styles/Login.module.css";

const FormCodePassword = ({ register, errors, isLoading }) => {

    return (
        <>
            <div className={`form-group`}>
                <h3 className={styles["title-account"]}>¿Se te olvidó <br/>la contraseña?</h3>
                <p className={styles["text-account"]}>Ingresa el código <br/>que llegó a tu correo electrónico:</p>
            </div>
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.code?.message!=="undefined" && errors?.code?.message!=="") ? "mb-1" : "mb-4"}`}>
                <input 
                    type="text" 
                    className={`form-control ${styles["form-input"]}`}
                    id="code" 
                    name="code"
                    aria-describedby="code"
                    placeholder="Código"
                    ref={register({required: {value: true, message: 'Requerido'}})}
                />
                </div>
                {errors.code && (
                <p className="text-danger mb-4 w-100 text-left">
                    {errors?.code?.message}
                </p>
            )}
                <div className="form-group">
                <button 
                    type="submit" 
                    className="btn btn-primary btn-block mb-4 pt-2"
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Siguiente"}</button>
				 </div>
        </>
    )
}

export default FormCodePassword