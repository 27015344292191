import { combineReducers } from "redux";

import users from 'services/reducers/userReducer';
import preorden from 'services/reducers/withdrawalReducer';
import offices from 'services/reducers/OfficeReducer';
import claims from 'services/reducers/claimReducer';
import questions from 'services/reducers/questionReducer';
import formaPagos from 'services/reducers/formaPagoReducer';

export default combineReducers({
	users,
	preorden,
	offices,
	claims,
	questions,
	formaPagos
})