import axios from 'axios';

const axiosInstance = axios.create({
	validateStatus() {
		return true;
	},
	withCredentials: false
});

const getHeaders = (isAplyToken, download) => {

	const token = localStorage.getItem("user") ? JSON.parse(atob(localStorage.getItem("user")))?.token : null
	
	let headers = { 
		"Access-Control-Allow-Headers" : "Content-Type",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
		'Accept': 'application/json',
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest",
		"Authorization":  `Bearer ${token}`,
	};


	if(download){
		return { responseType: 'blob', headers: {...headers} }
	}else{
		return { headers };
	}

    
};

const getHeadersForm = () => {
	const token = localStorage.getItem("user") ? JSON.parse(atob(localStorage.getItem("user")))?.token : null

	return {
	  headers: {
		"Access-Control-Allow-Headers" : "Content-Type",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
		'Accept': 'application/json',
		'Content-Type': `application/x-www-form-urlencoded`,
		"Authorization":  `Bearer ${token}`,
	  }
	}
  }

const API = {
	get: (endPoint) => {
		return axiosInstance.get(endPoint, getHeaders(false, false));
	},
	post: (endPoint, body) => {
	    return axiosInstance.post(endPoint, body, getHeaders(false, false));
	},
	put: (endPoint, body) => {
	    return axiosInstance.put(endPoint, body, getHeaders(false, false));
	},
	postDownload: (endPoint, body) => {
	    return axiosInstance.post(endPoint, body, getHeaders(false, true));
	},
	frontApp: (options) => {
	    return axios.request(options);
	},
	postFiles: (endPoint, body) => {
		return axiosInstance.post(endPoint, body, getHeadersForm())
	},
};

export default API;