import styles from "styles/Retiro.module.css";
import { useAditional } from "hooks/WithDrawals/useAditional";

const FormAditional = ({ toast, nextNameFrom }) => {

	const { onSubmit, register, handleSubmit, isLoading, lastFormPreOrden } = useAditional(toast)

	return (
		<section className="mt-4">
			<form onSubmit={handleSubmit(onSubmit)}>
			  	<div className="row mb-4">
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="observacion">Observaciones</label>
				      	<textarea 
				      		className="form-control" 
				      		id="observacion" 
				      		name="observacion"
				      		rows={5}
				      		cols={5}
				      		ref={register}
				      	></textarea>
				    </div>
			  	</div>
			  	{/**<div className="row mb-2">
				    <div className="col">
				      	<button className="btn btn-outline-primary btn-size-33 btn-block mr-3 font-10">
				           Imprimir ticket
				        </button>
				    </div>
			  	</div>**/}
			  	<div className={`${styles["btn-modal"]} mt-3`}>
				  	<button 
				  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
				  		onClick={()=>lastFormPreOrden(nextNameFrom)}
				  	>
			            Atrás
			        </button>
			        <button 
			        	type="submit"
			        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
			        	disabled={isLoading}
			        >
			            { isLoading ? "Procesando..." : "Finalizar" }
			        </button>
				</div>
			</form>
			<div className="row mb-3">
				  	<div className={`col ${styles["item-container"]}`}>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-selected"]}></div>
				  	</div>
			</div>
		</section>
	);
};

export default FormAditional;