import OrdenItem from "components/Withdrawals/OrdenItem";
import OrdenItemInvoice from "components/Withdrawals/OrdenItemInvoice";
import OrdenItemSend from "components/Withdrawals/OrdenItemSend";
import InfoDetail from "components/Withdrawals/Detail/InfoDetail";
import PanelSpinner from "components/Custom/Spinner/PanelSpinner";
import SpinnerCountSend from "components/Custom/Spinner/SpinnerCountSend";
import SpinnerInvoice from "components/Custom/Spinner/SpinnerInvoice";
import icon_inbox from "assets/images/icon-inbox.svg";
import styles from "styles/Retiro.module.css";

import { Link } from "react-router-dom";


const PanelInformationDsk = ({ title, listOrden, subtitle, optClassName, isVisibleHeader, 
	isVisibleTitle, isVisisbleAll, isLoadingDashboard, url }) => {

	return (
		<div className={`${styles["opt-container"]} ${styles[optClassName]}`}>
			<div className="mt-3">
				{
					isVisibleHeader && (
						<div className={styles["group-item"]}>
							<p className={`${styles["group-title"]} ${styles["group-title__calendar"]}`}>{title}</p>
							{ isVisisbleAll &&
								url!=="" ? <Link to={url}><p className={styles["group-view"]}>{subtitle}</p></Link>
								: <p className={styles["group-view"]}>{subtitle}</p>
								
							}
						</div>
					)
				}
				
				{
					isVisibleTitle && (
						<OrdenItem 
							fecha="Fecha"
							cliente="Nombre quien retira"
							tracking="No. de tracking"
							title_btn="Ver orden"
						 />
					)
				}

				{
					(listOrden?.name==="Estados de envíos") && (
						isLoadingDashboard ? <SpinnerCountSend />
						:
							<>
								{listOrden?.listDsk?.length>0 && listOrden?.listDsk?.map((item, index) => 
									<OrdenItemSend key={index} {...item} />
								)}
								{/**<section className="mt-5 mb-3">
									<Link to="/mis-retiros/busqueda">
										<button 
												className="btn btn-outline-primary btn-size-33 mr-5 btn-block mb-3 font-10" 
											>
											Ver historial de envíos
											</button>
									</Link>
								</section> */}
							</>
					)
				}

				{
					(listOrden?.name==="Mis facturas") && (
						isLoadingDashboard ? <SpinnerInvoice />
						:
						<>
							<section className="mt-5 mb-3">
									<button 
							  		className="btn btn-outline-primary btn-size-33 mr-5 btn-block mb-3 font-10" 
							  	>
						           Ver todas mis facturas
						        </button>
							</section>
							{listOrden?.listDsk?.length>0 && listOrden?.listDsk?.map((item, index) => 
								<OrdenItemInvoice key={index} {...item} />
							)}
						</>
					)
				}

				{
					(listOrden?.name==="Buscar un retiro") && (
						<InfoDetail isVisibleButton={true} />
					)
				}

				{
					(listOrden?.name!=="Mis facturas" && listOrden?.name!=="Estados de envíos" && listOrden?.name!=="Buscar un retiro") && (
					
						isLoadingDashboard ? <PanelSpinner />
						: listOrden?.listDsk?.length>0 ?
							 listOrden?.listDsk?.map((item, index) => 
									<OrdenItem key={index} {...item} />
								)
							: 
							<div className={styles["options-not-records"]}>
								<img src={icon_inbox} alt="No hay registros" />
								<p className={styles["item-text__subtitle-fact"]}>
									{
										listOrden?.name==="Mis retiros" ?
										"No hay retiros disponibles" :
										listOrden?.name==="Mis órdenes" ?
										"No hay órdenes disponibles" :
										listOrden?.name==="Notificaciones" ?
										"No hay notificaciones disponibles" :
										"No hay registros disponibles" 
									}
								</p>
							</div>
						
					)					
				}
			</div>
		</div>
	);
};

export default PanelInformationDsk;