import { Modal } from 'react-bootstrap';
import close_btn from "assets/images/close.png";
import styles from "styles/Retiro.module.css";
import endPoints from 'services/routes/';

const ModalTicket = ({ show, handleClose, infoPackage }) => {

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
            handleClose();
        }
    };

	return (
		<Modal 
			show={show} 
			onHide={handleClose} 
			className={styles["modal-withdrawals"]} 
			centered
			size="lg"
			>
	        <Modal.Header className={styles["header-modal"]}>
	          <Modal.Title className={styles["title-modal"]}>Informe</Modal.Title>
	          <div aria-hidden="true" className={styles["btn-close"]} onClick={()=>handleClose()} onKeyDown={(e) =>handleKeyDown(e)}>
	          	<img src={close_btn} alt="Cerrar" width={12} heigth={12} className="modal-close" />
	          </div>
	        </Modal.Header>
	        <Modal.Body>
                <section>
                    <p className={styles["subtitle-completed"]}>El número de retiro {infoPackage?.numero_retiro}<b></b></p>
                    <div className="embed-responsive embed-responsive-16by9">
                    <iframe title="Ticket de Retiro" className="embed-responsive-item" src={endPoints.retiros.previewRetiro(infoPackage?.numero_retiro)} width="100%"></iframe>
                    </div>
                    
                </section>
	        </Modal.Body>
	    </Modal>
	);
};

export default ModalTicket;