import { useState } from "react";
import { sendForm, resetFormClaim } from "services/actions/claimAction"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

export const useFormClaim = (toast) => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const { register, handleSubmit, errors, reset } = useForm({})

	const onSubmit =  async (data, type) => {
		try{
			setIsLoading(true)

			let response = await dispatch(sendForm(data))	
			setIsLoading(false)

			if(response.status){
				reset({
					detail_claim: "",
					claim_reason: ""
				})

				if(type==="modal"){
					dispatch(resetFormClaim("frm-completed"))
				}else{
					toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
				}
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}
			
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const resetInput = () => {
		reset({
			detail_claim: "",
			claim_reason: ""
		})
	}

	return {
		register, 
		handleSubmit, 
		errors,
		onSubmit,
		isLoading,
		resetInput
	}
}