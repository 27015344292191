import RowInvoice from "components/Withdrawals/RowInvoice";
import styles from "styles/Retiro.module.css";
import SpinnerInvoice from "components/Custom/Spinner/SpinnerInvoice";
import icon_inbox from "assets/images/icon-inbox.svg";


const TableInvoice = ({ title, subtitle, listInvoice, downloadFile, isLoadingDashboard }) => {

	return (
		<div className={styles["opt-container-table"]}>
			<div className={styles["group-item"]}>
				<p className={styles["group-title"]}>{title}</p>
				<p className={styles["group-view"]}>{subtitle}</p>
			</div>
			{
				!isLoadingDashboard ? (
					<div className={`mt-3 table-responsive`}>
						<table className="table table-sm">
						  <tbody>
						  	{
						  		listInvoice?.length>0 &&
						  		listInvoice?.map((item, key) => 
						  			<RowInvoice 
						  				key={key} 
						  				{...item} 
						  				status="completed" 
						  				downloadFile={downloadFile}
						  			/>
						  		)
						  	}
						  </tbody>
						</table>
					</div>
				) : (
					<SpinnerInvoice />
				)
			}
			{
				(!isLoadingDashboard && listInvoice?.length===0) && (
					<div className={styles["options-not-records__invoice"]}>
							<img src={icon_inbox} alt="No hay registros" />
							<p className={styles["item-text__subtitle-fact"]}>No hay facturas disponibles</p>
						</div>
				) 
			}
		</div>
	);
};

export default TableInvoice;