import { useState } from "react"
import { resetFormWithDrawal, nextFormPreOrden, saveMultipleDestination, resetDestination } from "services/actions/withdrawalAction"
import { getDataInitial } from "services/actions/officeAction";
import { useDispatch, useSelector } from "react-redux"


export const useModalWithDrawal = (toast) => {

	const dispatch = useDispatch()
	const [ show, setShow ] = useState(false);
	const { preorden, offices, formaPagos } = useSelector((state) => {
		return {
			preorden: state.preorden,
			offices: state.offices,
			formaPagos: state.formaPagos
		}
	})
	const { formViewActive, formPreOrden }  = preorden
	const { sucursales } = offices
	const { listFormaPagos } = formaPagos;


	const getInitial = async () => {

		await dispatch(getDataInitial())	
	}

	const openModal = () => {

		dispatch(resetFormWithDrawal())
		dispatch(resetDestination())
		setShow(!show);
	};

	const nextForm = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	const saveDestination = (value,  nameForm) => {

		dispatch(saveMultipleDestination(value))
		dispatch(nextFormPreOrden(nameForm))
	}

	const resetValudeDestination = (nameForm) => {

		dispatch(resetDestination())
		dispatch(saveMultipleDestination(true))
		dispatch(nextFormPreOrden(nameForm))
	}

	

	return {
		sucursales,
		formViewActive,
		openModal,
		show,
		nextForm,
		saveDestination,
		formPreOrden,
		resetValudeDestination,
		getInitial,
		listFormaPagos
	}
}