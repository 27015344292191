import { useState } from "react"
import { saveFormDestionation, nextFormPreOrden } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import template from 'templates/Plantilla-multiple-destinatario.xlsx'
import XLSX from 'xlsx'
import { validarRut } from "utils/ToolRUT/";
import { v4 as uuidv4 } from 'uuid'

export const useUploadTemplate = (toast) => {

	const dispatch = useDispatch()
	const { infoDestination, formPreOrden, configFormData } = useSelector((state) => state.preorden)
	const [ listSend, setListSend ] = useState([])
	const [items, setItems] = useState({ path: "", destinations: [] });

	const { register, handleSubmit, errors, control, setError, setValue } = useForm()

	const onSubmit = (data) => {
		try{
			const initialValue = 0;
			const valueRegisterCurrent = listSend.reduce(
				(accumulator, currentValue) => accumulator + currentValue?.destinations?.length,
				initialValue
			  )
		
			const total = valueRegisterCurrent + items?.destinations?.length
		
			if(total >400){
				toast.error("Solo se puede registrar como máximo 400 destinatario", {position: toast.POSITION.TOP_RIGHT});
				return false;
			}
			

			if(items?.destinations?.length ===0){
				toast.error("Adjunte la plantilla en excel con los destinatarios", {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			const validateHeader = validateHeaderTemplate(items?.destinations[0])

			if(validateHeader?.status){
				toast.error(validateHeader.description, {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			let errMessage = []
			///Validación de la longitud del RUT
			for(let index in items?.destinations){
				if(items?.destinations[index]?.rut?.length > 12){
					errMessage.push(items?.destinations[index]?.rut)
				}
			}

			if(errMessage?.length > 0){
				toast.error("Debe corregir el longitud de los siguientes RUT: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}
			///Validación del formato de RUT
			for(let index in items?.destinations){
				const response = validarRut(items?.destinations[index]?.rut)
				if(response?.status===false){
					errMessage.push(items?.destinations[index]?.rut)
				}
			}
	
			if(errMessage?.length > 0){
				toast.error("Debe corregir el formato de los siguientes RUT: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			///Validación de la longitud del teléfono
			for(let index in items?.destinations){
				if(items?.destinations[index]?.numero_de_contacto?.length>9){
					errMessage.push(items?.destinations[index]?.numero_de_contacto)
				}
			}

			if(errMessage?.length > 0){
				toast.error("Debe corregir la longitud de los siguientes números de teléfonos: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			///Validación del email
			for(let index in items?.destinations){
				let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
				const isValidEmail = regex.test(items?.destinations[index]?.correo)
				if(!isValidEmail){
					errMessage.push(items?.destinations[index]?.correo)
				}
			}

			if(errMessage?.length > 0){
				toast.error("Debe corregir el formato de los siguientes correos: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			///Validación de la cantidad
			for(let index in items?.destinations){
				if(isNaN(parseInt(items?.destinations[index]?.cantidad_de_paquetes))){
					errMessage.push(items?.destinations[index]?.rut)
				}else if(items?.destinations[index]?.cantidad_de_paquetes===0 || items?.destinations[index]?.cantidad_de_paquetes < 0){
					errMessage.push(items?.destinations[index]?.rut)
				}
			}

			if(errMessage?.length > 0){
				toast.error("Debe corregir el valor de la cantidad de paquetes en los siguientes RUT: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			///Validación de la cantidad
			for(let index in items?.destinations){
				if(isNaN(parseInt(items?.destinations[index]?.peso))){
					errMessage.push(items?.destinations[index]?.rut)
				}
			}

			if(errMessage?.length > 0){
				toast.error("Debe corregir el valor del peso en los siguientes RUT: "+errMessage?.join(","), {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			let sucursal_destino = document.querySelector("#sucursal_destino_id");
			let comuna_destino = document.querySelector("#comuna_destino_id");
			let forma_pago = document.querySelector("#forma_pago_id");

			const destionations = {
				destinations: items?.destinations,
				path: items?.path,
				cant: items?.destinations?.length,
				sucursal_destino_id: data?.sucursal_destino_id,
				sucursal_name: sucursal_destino?.options[sucursal_destino.selectedIndex]?.text,
				comuna_destino_id: data?.comuna_destino_id,
				comuna_name: comuna_destino?.options[comuna_destino.selectedIndex]?.text,
				id: uuidv4(),
				file: items?.file,
				forma_pago: forma_pago?.options[forma_pago.selectedIndex]?.text,
				forma_pago_id: data?.forma_pago_id,
			}
		
			setListSend(listSend => [...listSend, destionations])
			toast.success("Se ha agregado correctamente al listado", {position: toast.POSITION.TOP_RIGHT})
			setValue("sucursal_destino_id", "")
			setValue("comuna_destino_id", "")
			setValue("forma_pago_id", "")
			setItems({ path: "", destinations: [] })
			
		}catch(error){

			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const getDataPreview = () => {
		try{
			
			if(listSend.length ===0 && infoDestination.length ===0 ){
				toast.error("No hay datos ingresado en el listado", {position: toast.POSITION.TOP_RIGHT});
				return false;
			}

			let listDestions = []
			let configData = listSend;

			for(let index in listSend){
				let destionations = listSend[index]?.destinations.map(item => {
					return {
						...item,
						razon_social: item?.nombre,
						email: item?.correo,
						direccion: item?.direccion_destino,
						internal_code_number: "",
						rut: item?.rut,
						telefono: item?.numero_de_contacto,
						sucursal_destino_id: parseInt(listSend[index]?.sucursal_destino_id),
						sucursal_name: listSend[index]?.sucursal_name,
						comuna: parseInt(listSend[index]?.comuna_destino_id),
						comuna_name: listSend[index]?.comuna_name,
						listPackages: [{
							bulto: item?.cantidad_de_paquetes,
							peso: item?.peso
						}],
						id: uuidv4(),
						forma_pago_id: listSend[index]?.forma_pago_id,
						forma_pago: listSend[index]?.forma_pago,
					}
				})

				configData[index].destionationsFormat = destionations;

				listDestions = [ ...listDestions, ...destionations ]
			}

			dispatch(saveFormDestionation(listDestions, configData))
			
			dispatch(nextFormPreOrden(formPreOrden.multiple_destinatation ?  "destinationMultiple" : "package"))
			
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const updateFiles = (acceptedFiles, fileRejections) => {
		try{
			setItems({
				path: "",
				destinations: []
			})	

			if(fileRejections.length > 0){

				if(fileRejections[0].errors[0]?.code==="file-too-large"){
					toast.error("El tamaño máximo del archivo es de 1MB", {position: toast.POSITION.TOP_RIGHT});
					return false;
				}else if(fileRejections[0].errors[0]?.code==="file-invalid-type"){
					toast.error("Solo se acepta archivos con formato .xlsx, .xls", {position: toast.POSITION.TOP_RIGHT});
					return false;
				}
			}
		
			acceptedFiles.forEach((file) => {
				let reader = new FileReader()

				reader.onload = function () {
	
					let data = new Uint8Array(reader.result)
					let workbook = XLSX.read(data, {type: 'array'})
	
					let first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
					let result = XLSX.utils.sheet_to_json(first_worksheet, {header:2})
					
					setItems({
						path: file?.path,
						destinations: result,
						file: file
					})				
				};
				reader.readAsArrayBuffer(file);
			})		
			
		}catch(err){

			return { status: false, message: "Error en la carga de la data"}
		}
    };

	const validateHeaderTemplate = (item) => {
        let headers =  Object.keys(item)

        let itemHeaders = ["nombre", "rut", "direccion_destino", "numero_de_contacto", "correo", "cantidad_de_paquetes", "peso"] 
        let message = { status: false, description: ""}
        let itemNotValid = []
        let countColumn = 0
       
        if(headers.length===itemHeaders.length){
            for(const index in headers){

                if(!itemHeaders.includes(headers[index])){
                    message.status = true 
                    itemNotValid.push(headers[index])
                }else{
                    countColumn++
                }
            }
    
            if(countColumn!==itemHeaders.length){
                message = { status: true, description: "El encabezado debe tener solo 7 columnas, que son: nombre, rut, direccion_destino, numero_de_contacto, correo, cantidad_de_paquetes, peso"}
            }else{
                if(itemNotValid.length>0){
                    message = { status: true, description: "Verifique los nombre del siguiente encabezado:" + itemNotValid.join(",")}
                }
            }            
        }else{
            message = { status: true, description: "El encabezado debe tener solo 7 columnas, que son: nombre, rut, direccion_destino, numero_de_contacto, correo, cantidad_de_paquetes, peso"}
        }       

        return message
    };

	const onChangeInput = (event, type, name) => {
		const { value } = event.target
		dispatch(saveFormDestionation({ [name]: value!=="" ? value : ""}))
	}


	const lastFormPreOrden = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	const downloadFile = (event) => {
		event.preventDefault()
	
		let a = document.createElement('a');
        a.href = template;
        a.download = `plantilla-multiple-destinatario.xlsx`;
        a.click();
	}
	
	const deleteItemTable = (event, item) => {
		event.preventDefault()
		const listSendUpdate = listSend.filter(file => file.id!==item?.id)
		setListSend(listSend => listSendUpdate)
	}

	return {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		formPreOrden,
		onChangeInput,
		lastFormPreOrden,
		downloadFile,
		setError, 
		setValue,
		setListSend,
		updateFiles,
		listSend,
		getDataPreview,
		items,
		deleteItemTable,
		configFormData
	}
}