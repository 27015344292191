import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Retiro.module.css";

const SpinnerCountSend = () => {

	return (
		<>
		{
            	Array.from([1, 2]).map((item, key) => 
					<section key={key} className={`${styles["option-container"]} ${styles["border-ttracking"]}`}>
						<div className={styles["option-item"]}>
							<div className={styles["container-package"]}>
								<Skeleton width={50} height={40} count={1} />
								<div className={styles["item-text__container"]}>
									<p className={`${styles["item-text__subtitle-fact"]} mb-0`}><Skeleton width={100} height={20} count={1} /></p>
									<p className={`${styles["item-text__title-fact"]} mb-0`}><Skeleton width={100} height={20} count={1} /></p>
								</div>
							</div>
							<div>
								<p className={styles["text-count"]}><Skeleton width={60} height={20} count={1} /></p>
							</div>
						</div>
					</section>
				)
		}
		<section className="mt-5 mb-3">
				<button 
		  		className="btn btn-outline-primary btn-size-33 mr-5 btn-block mb-3 font-10" 
		  	>
	           <Skeleton width={120} height={20} count={1} />
	        </button>
		</section>
		</>
	)
}

export default SpinnerCountSend