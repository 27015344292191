import { useState } from "react"


export const useProfile = (toast) => {

    const [ tabSelected, setTabSelected ] = useState("form-account")

	const handleTabSelect = (event, tabSelected) => {
		event.preventDefault()
		setTabSelected(tabSelected)
	}


    return {
        handleTabSelect,
        tabSelected
    }
}