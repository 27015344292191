import { useState, useEffect } from "react";
import {  
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { BiX, BiEditAlt, BiSave } from "react-icons/bi"
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form"
import { onPaste, validateOnlyNumber } from "../../../utils/validationInput";
import { toast } from 'react-toastify'
import { useDispatch } from "react-redux"
import { editRowTablePackage, saveRowTablePackage } from "services/actions/withdrawalAction"


	
const TablePackage = ({  visible, index, bulto, peso, largo, ancho, alto, deleteRow, register, control, getValues }) => {
	
	const dispatch = useDispatch()
	const [ nroPeso, setNroPeso ] = useState(peso);
    const [ nroLargo, setNroLargo] = useState(largo);
    const [ nroAncho, setNroAncho ] = useState(ancho);
    const [ nroAlto, setNroAlto ] = useState(alto);
	const [ nroBulto, setNroBulto ] = useState(bulto);

    useEffect(() => {
      //setTotalPagar(valor_neto);
      setNroPeso(peso);
      setNroLargo(largo);
      setNroAncho(ancho);
      setNroAlto(alto);
	  setNroBulto(bulto)
    }, [peso, largo, ancho, alto, bulto])

	const editRow = (event, index, visible) => {
		event.preventDefault()
	
		if(visible===false){
			dispatch(editRowTablePackage(index))
		}else{
			//const data = getValues()
			//const item = data.cargas[index]
	
			if(nroPeso!=="" && nroLargo!=="" && nroAncho!=="" && nroAlto!=="" && bulto!==""){
				dispatch(saveRowTablePackage({
					alto: nroAlto,
					ancho: nroAncho,
					bulto: nroBulto,
					largo: nroLargo, 
					peso: nroPeso
				}, index))
			}else{
				toast.error("Debe completar los datos", {position: toast.POSITION.TOP_RIGHT})
			}
		}
	} 

	const validateBulto = (event) => {
   
		if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
		  toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
		  return false
		}else{
			setNroBulto(event.target.value)
		}
	  }

	return (
		<tr>
			<td>
				<input
					type={visible ? "text" : "hidden"}
					name={`cargas[${index}].bulto`}
					value={nroBulto}
					ref={register}
					className="form-control"
					maxLength={2}
					onPaste={(event) => onPaste(event)}				
					onKeyPress={(event) => validateOnlyNumber(event)}
					onBlur={(event) => validateBulto(event)}
					onChange={(event) => setNroBulto(event.target.value)}
					/>
				{!visible && nroBulto}
			</td>
			<td>
			{
				visible ? (
				<OverlayTrigger overlay={<Tooltip id="tooltip-peso_total">Kg</Tooltip>}>
					<Controller
					control={control}
					name={`cargas[${index}].peso`}
					render={(props) => (
						<NumericFormat
						allowNegative={false}
						className="form-control"
						decimalScale={2}
						name={props.name}
						id={props.name}
						fixedDecimalScale={false}
						placeholder="Peso (Kg)"
						isAllowed={({floatValue}) => {
							// Define your validation rules here
							return true;
						}}
						onPaste={(e) => e.preventDefault()}
						decimalSeparator={','}
						value={nroPeso}
						//defaultValue={ordenInfo.valor_declarado_carga}
						onValueChange={(values) => {
							props.onChange(values.floatValue);
							setNroPeso(values.floatValue);
						}}
						///onBlur={() => editM3()}
						/>
					)}
					autoComplete="off"
								/>
				</OverlayTrigger>
				) : (
				<input
					type="hidden"
					name={`cargas[${index}].peso`}
					defaultValue={nroPeso}
					ref={register}
					className="form-control"
					step="0.01" 
					min="0"
				/>
				)
				}
				{!visible && 
					<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroPeso}
					/>
				}
			</td>
			<td>
			{
				visible ? (
				<OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
					<Controller
					control={control}
					name={`cargas[${index}].largo`}
					render={(props) => (
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							name={props.name}
							id={props.name}
							fixedDecimalScale={false}
							placeholder="Largo (m)"
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							value={nroLargo}
							//defaultValue={ordenInfo.valor_declarado_carga}
							onValueChange={(values) => {
								props.onChange(values.floatValue);
								setNroLargo(values.floatValue);
							}}
							///onBlur={() => editM3()}
							/>
						)}
						autoComplete="off"
					/>
				</OverlayTrigger>
				) : (
				<input
					type="hidden"
					name={`cargas[${index}].largo`}
					defaultValue={nroLargo}
					ref={register}
					className="form-control"
					step="0.01" 
					min="0"
				/>
				)
				}
				{!visible && 
					<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroLargo}
					/>
				}
			</td>
			<td>
			{
				visible ? (
				<OverlayTrigger overlay={<Tooltip id="tooltip-ancho">m</Tooltip>}>
					<Controller
					control={control}
					name={`cargas[${index}].ancho`}
					render={(props) => (
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							name={props.name}
							id={props.name}
							fixedDecimalScale={false}
							placeholder="Ancho (m)"
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							value={nroAncho}
							//defaultValue={ordenInfo.valor_declarado_carga}
							onValueChange={(values) => {
								props.onChange(values.floatValue);
								setNroAncho(values.floatValue);
							}}
							///onBlur={() => editM3()}
							/>
						)}
						autoComplete="off"
					/>
				</OverlayTrigger>
				) : (
				<input
					type="hidden"
					name={`cargas[${index}].largo`}
					defaultValue={nroAncho}
					ref={register}
					className="form-control"
					step="0.01" 
					min="0"
				/>
				)
				}
				{!visible && 
					<NumericFormat
						displayType="text"
						className="text-padding"
						decimalScale={2}
						fixedDecimalScale={false}
						decimalSeparator={','}
						value={nroAncho}
					/>
				}
			</td>
			<td>
			{
				visible ? (
				<OverlayTrigger overlay={<Tooltip id="tooltip-alto">m</Tooltip>}>
					<Controller
					control={control}
					name={`cargas[${index}].alto`}
					render={(props) => (
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							name={props.name}
							id={props.name}
							fixedDecimalScale={false}
							placeholder="Alto (m)"
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							value={nroAlto}
							//defaultValue={ordenInfo.valor_declarado_carga}
							onValueChange={(values) => {
								props.onChange(values.floatValue);
								setNroAlto(values.floatValue);
							}}
							///onBlur={() => editM3()}
							/>
						)}
						autoComplete="off"
					/>
				</OverlayTrigger>
				) : (
				<input
					type="hidden"
					name={`cargas[${index}].alto`}
					defaultValue={nroAlto}
					ref={register}
					className="form-control"
					step="0.01" 
					min="0"
				/>
				)
				}
				{!visible && 
					<NumericFormat
						displayType="text"
						className="text-padding"
						decimalScale={2}
						fixedDecimalScale={false}
						decimalSeparator={','}
						value={nroAlto}
					/>
				}
			</td>								
			<td>
				<button type="button" className={`btn ${!visible ? "btn-warning" : "btn-success"} btn-sm mr-1 pl-1 pr-1`} onClick={(event)=>editRow(event, index, visible)}>
					{!visible ? <BiEditAlt style={{fontSize: '20px'}} /> : <BiSave style={{fontSize: '20px'}} /> }
				</button>
				<button type="button" className="btn btn-danger btn-sm pl-1 pr-1" onClick={(event)=>deleteRow(event, index)}>
					<BiX style={{fontSize: '20px'}} />
				</button>
			</td>
		</tr>
	)
}

export default TablePackage