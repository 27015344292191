import API from 'services/api/index';
import { 
    LOGIN,
    LOGOUT,
	FORGOT_PASSWORD
} from 'services/types/userType';
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const loginUser = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.auth.login, body);

		const { status, message, token } = response.data
		if(status){
			localStorage.setItem("user", token);
			
			let userData = JSON.parse(atob(token))
		
		    const payload = {
				rut: body.rut,
				fullName: userData?.fullName,
				token: userData?.token,
				typeClient: userData?.typeClient,
				address: userData?.address,
				telephone: userData?.telephone,
				email: userData?.email,
				sucursal_id: userData?.sucursal_id,
				comuna_id: userData?.comuna_id
			}

		    dispatch(requestSuccess(LOGIN, payload));
		}

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const logout = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get(endPoints.auth.logout)

		  	const { status, message } = response.data

		    if(status){
		       localStorage.clear()
		       const payload = {
					rut: "",
					fullName: "",
					token: "",
					typeClient: "",
					address: "",
					telephone: ""
			    }
		       dispatch(requestSuccess(LOGOUT, payload))
		    }
	        resolve({ status, message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
};

export const registerClient = (body) => async (dispatch, getState) => {
	try{
		let response = await API.post(endPoints.client.register, body);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const forgotPassword = (body) => async (dispatch, getState) => {
	try{
		let response = await API.post(endPoints.password.forgot, body);

		const { status, message } = response.data

		if(status){
			const payload = {
				email: body.email,
				rut: body.rut
			}
			dispatch(requestSuccess(FORGOT_PASSWORD, payload))
		}		

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};


export const codeResetPassword = (body) => async (dispatch, getState) => {
	try{
		const {  emailForgot, rutForgot } = getState().users

		const data = { 
			email: emailForgot, 
			code: body.code,
			rut: rutForgot
		}

		let response = await API.post(endPoints.password.code, data);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetNewPassword = (body) => async (dispatch, getState) => {
	try{
		const {  emailForgot, rutForgot } = getState().users

		const data = { 
			email: emailForgot, 
			password: body.password,
			rut: rutForgot
		}

		let response = await API.post(endPoints.password.reset, data);

		const { status, message } = response.data

		if(status){
			const payload = ""
			dispatch(requestSuccess(FORGOT_PASSWORD, payload))
		}		

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetPasswordProfile = (body) => async (dispatch, getState) => {
	try{
		const {  rut } = getState().users

		const data = { 
			rut, 
			passwordCurrent: body.passwordCurrent,
			password: body.password
		}

		let response = await API.post(endPoints.profile.password, data);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const updateProfile = (body) => async (dispatch, getState) => {
	try{
		const {  rut } = getState().users

		const data = { 
			rut, 
			...body
		}

		let response = await API.post(endPoints.profile.client, data);

		const { status, message } = response.data

		if(status){		
			let userData = JSON.parse(atob(localStorage.getItem("user")))

			let payload = {
				typeClient: userData?.typeClient,
				fullName: body.fullname,
				rut: body.rut,
				address: body.address,
				telephone: body.telephone,
				email: body.email,
				token: userData?.token
			}

			localStorage.setItem("user", btoa(JSON.stringify(payload)));
			
		    dispatch(requestSuccess(LOGIN, payload));
		}

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const searchClientByCode = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.auth.bycode, body);

		const { status, message, data } = response.data

		return { status, message, data };
		
    }catch(error){
      return {status: false, message:error}
    }
};