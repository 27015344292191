import icon_1 from "assets/images/icon-white/calendar.svg"
import icon_2 from "assets/images/icon-white/clipboard.svg"
import icon_3 from "assets/images/icon-white/shield.svg"
import icon_4 from "assets/images/icon-white/location.svg"


export const dataInitialState = () => {

	const myPackageSents = [
		{ title: "Paquetes enviados", count: 0 },
		{ title: "Paquetes retirados", count: 0 }
	];

	const listOptionsDashboard = [
		{ name: "Mis retiros", icon: icon_1, selected: true, list: [], title: "Encuentra tus paquetes", subtitle: "Ingrese su número de seguimiento y vea los detalles sobre sus paquetes", isLoading: false },
		{ name: "Mis órdenes", icon: icon_1, selected: false, list: [], title: "Encuentra tus paquetes", subtitle: "Ingrese su número de seguimiento y vea los detalles sobre sus paquetes", isLoading: false },
		{ name: "Estados de envíos", icon: icon_2, selected: false, list: myPackageSents, listDsk: myPackageSents, title: "Encuentra tus paquetes", subtitle: "Ingrese su número de seguimiento y vea los detalles sobre sus paquetes", isLoading: false },
		{ name: "Notificaciones", icon: icon_3, selected: false, list: [], title: "Encuentra tus paquetes", subtitle: "Ingrese su número de seguimiento y vea los detalles sobre sus paquetes", isLoading: false },
		{ name: "Mis facturas", icon: icon_4, selected: false, list: [], title: "Encuentra tus paquetes", subtitle: "Ingrese su número de seguimiento y vea los detalles sobre sus paquetes", isLoading: false },
	]

	const listOptionsRetiro = [
		{ name: "Orden de retiro", icon: icon_1, selected: true, list: [], title: "Orden de retiro", subtitle: "Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee." },
		{ name: "Orden de flete", icon: icon_1, selected: false, list: [], title: "Orden de flete", subtitle: "Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee." },
		{ name: "Mis facturas", icon: icon_2, selected: false, list: [], title: "Mis facturas", subtitle: "Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee." },
		{ name: "Buscar un retiro", icon: icon_3, selected: false, list: [], title: "Buscar un retiro", subtitle: "Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee." }
	]

	return {
		myPackageSents,
		listOptionsRetiro,
		listOptionsDashboard
	}
}