import CarrouselOrden from "components/Withdrawals/Detail/CarrouselOrden";
import styles from "styles/Retiro.module.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const LoadingDetailOrden = ({ infoPackage }) => {

	return (
		<>
            <section className={`${styles["option-container"]} ${styles["paddinglr-0"]}`}>
                <div className="w-100">
                    <div className={styles["option-item"]}>
                        <p className={styles["item-text__title-fact"]}><Skeleton width={100} height={20} count={1} /></p>
                        <p className={`${styles["option-details__view"]}`}><Skeleton width={100} height={18} count={1} /></p>
                    </div>
                </div>
            </section>
            <div className={styles["detail-item"]}>
                <div className={styles["detail-item__container"]}>
                    <div className={`${styles["detail-item__text"]}`}>
                        <p className={`${styles["item-text__subtitle"]} mb-0`}>Remitente</p>
                        <p className={`${styles["item__title"]} mb-0`}><Skeleton width={100} height={18} count={1} /></p>
                    </div>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
                <div className={styles["detail-item__container"]}>
                    <div className={`${styles["detail-item__text"]}`}>
                        <p className={`${styles["item-text__subtitle"]} mb-0`}>Fecha</p>
                        <p className={`${styles["item__title"]} mb-0`}><Skeleton width={100} height={18} count={1} /></p>
                    </div>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
                <div className={styles["detail-item__container"]}>
                    <div className={`${styles["detail-item__text"]}`}>
                        <p className={`${styles["item-text__subtitle"]} mb-0`}>Cantidad</p>
                        <p className={`${styles["item__title"]} mb-0`}><Skeleton width={100} height={18} count={1} /></p>
                    </div>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
                <div className={styles["detail-item__container"]}>
                    <div className={`${styles["detail-item__text"]}`}>
                        <p className={`${styles["item-text__subtitle"]} mb-0`}>Oficina origen</p>
                        <p className={`${styles["item__title"]} mb-0`}><Skeleton width={100} height={18} count={1} /></p>
                    </div>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
                
                <section>
                    <p className={styles["title-det-orden"]}>Ordenes de flete asociadas</p>
                    <section className={styles["flex-all"]}>
                        <CarrouselOrden preordens={infoPackage?.preorden} loading={true} />
                    </section>
                    
                </section>
                <div className={styles["detail-item__container"]}>
                    <p className={`${styles["item-text__subtitle"]} mb-2 mt-2`}>Observación</p>
                    <p className={`${styles["item__title"]}`}>
                        <Skeleton width="100%" height={100} count={1} />
                    </p>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
            </div>
        </>
	);
};

export default LoadingDetailOrden;