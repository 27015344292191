import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Retiro.module.css";

const SpinnerInvoice = () => {

	return (
		<section className="text-center">
			<div className={`mt-3 table-responsive`}>
				<table className="table table-sm">
				  <tbody>
				  	{
		            	Array.from([1, 2, 3, 4, 5, 6, 7]).map((item, key) => 
		            		<tr key={key}>
		            			
			            		{
			            			Array.from([1, 2, 3, 4, 5]).map((item, key) => 
			            			<td key={key} className={styles["item-td"]}><Skeleton width={120} height={20} count={1} /></td>
			            				)
			            		}
		            		</tr>
			            )
			        }
				  </tbody>
				</table>
			</div>
			
		</section>
	)
}

export default SpinnerInvoice