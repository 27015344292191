import { useState } from "react"
import { saveFormRetiro, nextFormPreOrden, saveFromPackage, resetDestination, saveFormReceive } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"


export const useAditional = (toast) => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const { formPreOrden } = useSelector((state) => state.preorden)

	const { register, handleSubmit, errors } = useForm({
		defaultValues: {
			observacion: formPreOrden?.observacion
		}
	})

	const onSubmit =  async (data, event) => {
		try{

			setIsLoading(true)
			let body = {
				observacion: typeof data?.observacion === "undefined" ? "" : data?.observacion
			}

			dispatch(saveFormReceive(body, "completed"))
			dispatch(saveFromPackage())
			dispatch(resetDestination())

			const response = await dispatch(saveFormRetiro())

			setIsLoading(false)

			if(response.status){
				dispatch(nextFormPreOrden("completed"))
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}
			
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const lastFormPreOrden = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	return {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		isLoading,
		lastFormPreOrden,
		formPreOrden
	}
}