import MenuOptions from "components/Custom/MenuOptions";
import MainContainer from "components/Custom/MainContainer";
import styles from "styles/Retiro.module.css";
import retiro_new from "assets/images/retiro-new.png";
import search from "assets/images/search.png";
import { onPaste, validateOnlyNumber } from "../../utils/validationInput";

const ContainerOptions = ({ title, subtitle, menuOptionsList, changeOptions, isInputSearch, isBtnRetiro=true, openModal }) => {
	
	return (
		<div className="row">
			<div className="col-12">
				<MainContainer
					title={title}
					subtitle={subtitle}
				/>
				{
					isInputSearch && (
						<div className={`${styles["detail-item__container"]} ${styles["detail-item__input"]}`}>
							<input 
								type="text"
								placeholder="# Número de tracking"
								id="search"
								name="search"
								className={styles["input-search"]}
								onPaste={(event) => onPaste(event)}				
								onKeyPress={(event) => validateOnlyNumber(event)}
								maxLength={20}
							/>
							<span className={styles["input-search__icon"]}></span>
							<div className={styles["detail-item__search"]}>
								<img src={search} alt="Buscar" width={14} heigth={14} />
							</div>
						</div>
					)
				}
				
			</div>
			<div className="col-12">
				{isBtnRetiro && (
					<button 
						type="button" 
						className={`mt-3 btn btn-secondary btn-block btn-size-42 btn-new-retiro`}
						onClick={()=>openModal()}
					>
						<div className="btn-image">
							<img src={retiro_new} alt="Retiro Nuevo" width={17} heigth={17} />
						</div>
						Envío nuevo
					</button>
				)}
				
				{menuOptionsList.length>0 && <MenuOptions menuOptionsList={menuOptionsList} changeOptions={changeOptions} />}
			</div>
		</div>
	)
}

export default ContainerOptions