import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import styles from "styles/Login.module.css";

const ResetPassword = ({ register, errors, isLoading, handleBlurRut, control, setError, handleChangeEmail, setValue }) => {

    return (
        <>
            <div className={`form-group`}>
                <h3 className={styles["title-account"]}>¿Se te olvidó <br/>la contraseña?</h3>
                <p className={styles["text-account"]}>No te preocupes, podemos ayudarte, ingresa tu RUT y correo electrónico para continuar:</p>
            </div>
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.rut?.message!=="undefined" && errors?.rut?.message!=="") ? "mb-1" : "mb-4"}`}>
                
                <Controller
                    render={(props) => (
                        <MaskedInput
                            mask={RutTextMask}
                            className={`form-control ${styles["form-input"]}`} 
                            placeholder="RUT"
                            id="rut" 
                            name="rut"
                            value={props.value}
                            onChange={(value)=>{
                                props.onChange(value)
                            }}
                            onBlur={(event) => handleBlurRut(event, setError)}
                        />
                    )}
                    control={control}
                    name="rut"  
                    rules={{required: {value: true, message: 'Requerido'}}}
                />
            </div>
            {errors.rut && (
                <p className="text-danger mb-4 w-100 text-left">
                    {errors?.rut?.message}
                </p>
            )}
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.email?.message!=="undefined" && errors?.email?.message!=="") ? "mb-1" : "mb-4"}`}>
                <input 
                    type="email" 
                    className={`form-control ${styles["form-input"]}`}
                    id="email" 
                    name="email"
                    aria-describedby="email"
                    maxLength={200}
                    placeholder="Correo electrónico"
                    ref={register({
                        required: {value: true, message: 'Requerido'},
                        //eslint-disable-next-line
						pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
                    })}
                    onBlur={(event) => handleChangeEmail(event, "", setError, setValue)}
                />
                <span className={styles["icon-email"]}></span>
            </div>
            {errors.email && (
                <p className="text-danger mb-4 w-100 text-left">
                    {errors?.email?.message}
                </p>
            )}
            <div className="form-group">
                <button 
                    type="submit" 
                    className="btn btn-primary btn-block mb-4 pt-2"
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Siguiente"}</button>
            </div>
        </>
    )
}

export default ResetPassword