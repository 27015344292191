import { useState } from "react";
import { sendReplyConversation } from "services/actions/claimAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'

export const useRepleyClaim = () => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const { register, handleSubmit, errors, reset } = useForm({defaultValues:{message:""}})
    const { claimSelected } = useSelector((state) => state.claims)
    const [ isReply, setIsReply ] = useState(false);

	const onSubmit =  async (data, type) => {
		try{
			setIsLoading(true)
            const conversation = claimSelected[claimSelected.length-1]

            const body = {
                detail_claim: data?.message,
                claim_reason: conversation?.subject?.includes("RE: ") ? conversation?.subject : `RE: ${conversation?.subject}`,
                conversation_id: conversation?.conversation_id
            }

			let response = await dispatch(sendReplyConversation(body))	
			setIsLoading(false)

			if(response.status){
				reset({message: ""})
                setIsReply(!isReply)
                toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			} 
			
		}catch(error){
			toast.error("Hubo un error, al enviar el mensaje", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const updateReply = () => {
		reset({
			message: ""
		})
        setIsReply(!isReply)
	}


	return {
		register, 
		handleSubmit, 
		errors,
		onSubmit,
		isLoading,
        isReply,
        updateReply
	}
}