import API from 'services/api/index';
import { 
	LIST_CLAIMS,
	LOADING_CLAIMS,
	ADD_CLAIM_SEARCH,
	SELECT_CLAIM,
	RESET_FORM_CLAIM,
	LOADING_MODAL_CLAIM_DETAIL
} from 'services/types/claimType';

import endPoints from 'services/routes/';



export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getDataInitialClaim = () => async (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_CLAIMS, true));

		const {  userData } = getState().users;

		let body = {
			rut: userData?.rut
		}

		let response = await API.post(endPoints.consultationAndComplaint.list, body);

		const { status, message, data } = response.data

		const payload = {
			loadingClaim: false,
			listClaimsSearch: data.map(item => {
				return {
					titleMain: `No. ${item.conversation_id}`,
					titleSecondary: item.created_at,
					valueMain : "Ver reclamo",
					valueSecondary: item?.subject.slice(0,40),
					body: item?.body,
					subject: item?.subject,
					conversation_id: item?.conversation_id,
					is_notification: item?.is_notification
				}
			}),
			listFeaturesClaim: []
		}
		
		dispatch(requestSuccess(LIST_CLAIMS, payload));

		return { status, message }
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const sendForm = (body) => async (dispatch, getState) => {
	try{
		const {  userData } = getState().users
		const {  listClaimsSearch } = getState().claims

		const dataSend = {
			client: {
				fullname: userData?.fullName,
				email: userData?.email,
				rut: userData?.rut
			},
			body: body?.detail_claim,
			subject: body?.claim_reason
		}

		let response = await API.post(endPoints.consultationAndComplaint.sendForm, dataSend);

		const { status, message, data } = response.data

		if(status){
	
			let list = listClaimsSearch
	
			let newClaim = {
				titleMain: `No. ${data.nro_message}`,
				titleSecondary: data.created_at,
				valueMain : "Ver reclamo",
				valueSecondary: body?.claim_reason.slice(0,50),
				body: data?.body,
				subject: body?.claim_reason,
				conversation_id: data?.nro_message
			}
	
			list.unshift(newClaim)

			const payload = {
				listClamsNew: list
			}
	
			dispatch(requestSuccess(ADD_CLAIM_SEARCH, payload))
		}		

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};


export const resetFormClaim = (nameForm) => (dispatch, getState) => {
	let formViewActive = {}
	
	if(nameForm==="frm-initial"){
		formViewActive =  {
			title: "Reclamo",
			form: "frm-initial"
		}
	}else if(nameForm==="frm-completed"){
		formViewActive =  {
			title: "Reclamo",
			form: "frm-completed"
		}
	}

	const payload = { formViewActive }

	dispatch(requestSuccess(RESET_FORM_CLAIM, payload));	
};

export const getSelectedClaim = (body) => async (dispatch, getState) => {
	try{
		let response = await API.post(endPoints.consultationAndComplaint.getMessage, body);

		const { status, message, data } = response.data

		const payload = {
			claimSelected: status ? data : []
		}

		dispatch(requestSuccess(SELECT_CLAIM, payload))	

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const sendReplyConversation = (body) => async (dispatch, getState) => {
	try{
		const {  userData } = getState().users
		const {  claimSelected } = getState().claims

		const dataSend = {
			client: {
				fullname: userData?.fullName,
				email: userData?.email,
				rut: userData?.rut
			},
			body: body?.detail_claim,
			subject: body?.claim_reason,
			conversation_id: body?.conversation_id
		}

		let response = await API.post(endPoints.consultationAndComplaint.sendRepley, dataSend);

		const { status, message, data } = response.data

		if(status){
	
			let list = claimSelected
	
			let newClaim = {
				answer_from: "Portal web",
				body: body?.detail_claim,
				conversation_id: body?.conversation_id,
				email: userData?.email,
				full_name: userData?.fullName,
				subject: body?.claim_reason,
				nro_message: data?.nro_message,
				date_text: data?.created_at
			}

			list.push(newClaim)

			const payload = {
				claimSelected: list
			}
	
			dispatch(requestSuccess(SELECT_CLAIM, payload))	
		}	

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const loadingSearch = (value) => async (dispatch, getState) => {
	dispatch(requestSuccess(LOADING_MODAL_CLAIM_DETAIL, value))
};

export const updateListClaim = (conversation_id) => async (dispatch, getState) => {
	const {  listClaimsSearch } = getState().claims

	let list = listClaimsSearch

	const claimIndex = list.findIndex(item => item?.conversation_id===conversation_id);
	if(claimIndex!==-1){
		list[claimIndex].is_notification = !list[claimIndex].is_notification;
	}

	const payload = {
		listClamsNew: list
	}

	dispatch(requestSuccess(ADD_CLAIM_SEARCH, payload))	
};

export const updateStatusMessage = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.consultationAndComplaint.updateMessage, body);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};