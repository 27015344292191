import download from "assets/images/download.png";
import styles from "styles/Retiro.module.css";

const RowInvoice = ({ number_invoice, cliente, total, url, date_created, downloadFile, status}) => {

	return (
		<tr>
	      <th>
	      	<div  className="d-flex">
	      		<div className="ml-2">
	      			<a href="/" onClick={(event)=>downloadFile(event, url, number_invoice)}>
	      				<img src={download} alt="Descargar" width={11} height={15} />
	      			</a>
	      		</div>
	      	</div>
	      </th>
	      <td className={styles["item-td"]}>No. {number_invoice}</td>
	      <td className={styles["item-td"]}>{date_created}</td>
	      <td className={styles["item-td"]}>{cliente}</td>
	      <td className={styles["item-td"]}>${total}</td>
	      <td className={styles["item-td"]}>
	      	<div className={styles[`${status==="pending" ? "item-status-pending" : "item-status"}`]}>
	      		<div className={styles[`${status==="pending" ? "item-status__pending" : "item-status__completed"}`]}></div>
	      		{status==="pending" ? "Pendiente" : "Pagado" }
	      	</div>
	      </td>
	    </tr>
	);
};

export default RowInvoice;