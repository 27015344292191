import React from "react";

import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import VerdanaRegular from 'assets/fonts/Verdana.ttf'
import VerdanaRegularItalic from 'assets/fonts/Verdana-Italic.ttf'
import VerdanaBold from 'assets/fonts/Verdana-Bold.ttf'
import VerdanaBoldItalic from 'assets/fonts/Verdana-Bold-Italic.ttf'

// Register font
Font.register({ 
  family: 'Verdana',
  fonts: [
    {
      src: VerdanaRegular,
    },
    {
      src: VerdanaBold,
      fontWeight: 'bold',
    },
    {
      src: VerdanaBoldItalic,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    {
      src: VerdanaRegularItalic,
      fontWeight: 'normal',
      fontStyle: 'italic',
    }
  ]
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Verdana',
    },
	title: {
		paddingTop: '45px',
        fontWeight: 'bold',
        paddingLeft: '4px'
	}  
});

const TicketCorrelativo = ({ numero_correlativo }) => {

  return (
    
       <Page size={[212.59,113.38]} style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>{numero_correlativo}</Text>
          </View>
        </Page>

  );
};

export default TicketCorrelativo;