import { Modal, Spinner } from "react-bootstrap"

const ModalSpinner = ({ spinnerShow, closeSpinnerModal, description}) => {

	return (
		<Modal
	        size="sm"
	        show={spinnerShow}
	        backdrop="static"
        	keyboard={false}
	        aria-labelledby="example-modal-sizes-title-sm"
	        dialogClassName="modal-spinner"
	        centered
	      >
	        <Modal.Body>
	        	<div className="d-flex justify-content-center">
	        		<Spinner animation="grow" variant="dark" />
	        		<Spinner animation="grow" variant="dark" />
	        		<Spinner animation="grow" variant="dark" />
	        		<Spinner animation="grow" variant="dark" />
	        	</div>
	        	<p className="text-white text-center">{description}</p>
	        </Modal.Body>
	      </Modal>
	)
}

export default ModalSpinner