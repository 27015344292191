import { useState, useEffect } from "react";
import { getMyPackagesList, getMyOrdersList, loadingSearchPackages,
	exportExcelPDFPackages, exportExcelPDFOrders, resetMyPackagesList } from "services/actions/withdrawalAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"



export const useSearchPackage = () => {

	const dispatch = useDispatch()
	const { register, handleSubmit, control, getValues } = useForm()
	const { listSearchPackages, isLoadingSearchPackages } = useSelector((state) => state.preorden)
	const [ isLoading, setIsLoading ] = useState(false)
	const [pageOffset, setPageOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0)

	const onSubmit =  async (data, typeSearch) => {
		await getListAwait(data, typeSearch, 0)
		
	}

	const getListAwait =  async (data, typeSearch, page) => {
		dispatch(loadingSearchPackages())
		setIsLoading(true)

		let body = {
			fromDate: document.getElementById("fecha_orden_from").value,
			toDate: document.getElementById("fecha_orden_to").value
		}

		let response = {}
		if(typeSearch==="packages"){
		
			response = await dispatch(getMyPackagesList(page+1, 30, body))
		}else if(typeSearch==="orders"){
			response = await dispatch(getMyOrdersList(page+1, 30, body))
		}
		
		if(response?.status){
			setPageCount(response?.total_paginate ?? 0)
			setPageOffset(page)
		}
		


		setIsLoading(false)
		
	}

	const downloadSearch = async (event, typeDownload, typeSearch) => {
		event.preventDefault()
		let nameTypeSearch = typeSearch==="packages" ? "retiros" : "ordenes"
		let nameFile = typeDownload==="excel" ? `listado-${nameTypeSearch}.xlsx` : `listado-${nameTypeSearch}.pdf` 

		if(typeSearch==="packages"){
			await dispatch(exportExcelPDFPackages(typeDownload, nameFile))	
		}else{
			await dispatch(exportExcelPDFOrders(typeDownload, nameFile))	
		}
	}

	const handlePageChange = async (event) => {
	
		// TODO Only change displayed selected page
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
  
		const data = getValues()
		await getListAwait(data, "orders", event.selected);
	};

	const handlePageChangeRetiro = async (event) => {
	
		// TODO Only change displayed selected page
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
  
		const data = getValues()
		await getListAwait(data, "packages", event.selected);
	};

	useEffect(() => {
		dispatch(resetMyPackagesList())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const formElement = { handleSubmit, onSubmit, control, isLoading }

	return {
		register, 
		handleSubmit,
		onSubmit,
		control,
		listSearchPackages,
		downloadSearch,
		isLoading,
		isLoadingSearchPackages,
		handlePageChange,
		pageCount,
		pageOffset,
		formElement,
		handlePageChangeRetiro
	}
}