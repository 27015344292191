import { useState } from "react"
import styles from "styles/Retiro.module.css";
import icon_arrow from "assets/images/icon-white/arrow.svg"


const MenuOptions = ({ menuOptionsList, changeOptions }) => {

	const [ hideOption, setHideOption ] = useState(true)
	const [ selectOption, setSelectOption ] = useState(menuOptionsList[0])

	const handleOpenOptions = () =>  {
		setHideOption(!hideOption)
	}

	const handleSelectOption = (item) => {
		setSelectOption(item)
		changeOptions(item)
		handleOpenOptions()
	}

	return (
		<section>
	      	<div className={styles["selector"]}>
		        <div className={styles["select-field"]} onClick={()=>handleOpenOptions()}>
		            <div className="d-flex">
		            	<img src={selectOption.icon} alt={selectOption.name} className={styles["icon-selected"]} />
		            	<p className={styles["select-text"]}>{selectOption.name}</p>
		            </div>
		            <img src={icon_arrow} alt="Arrow" className={styles["arrowIcon"]} />
		        </div>

		        <ul className={`${styles["list-options"]} ${styles[hideOption ? "hide-options" : ""]}`}>
		        	{
		        		menuOptionsList.length>0 &&
		        		menuOptionsList.map((item,key) => 
		        			<li className={styles["options"]} 
		        				onClick={()=>handleSelectOption(item)}
		        				key={key}
		        			>
				              <img src={item.icon} alt={item.name} />
				              <p>{item.name}</p>
				            </li>
		        		)
		        	}
		        </ul>
	     	</div>
	  	</section>

	)
}

export default MenuOptions
