import Layout from "components/Layout/Layout";
import FormSearchAll from "components/Withdrawals/ViewAll/FormSearchAll";
import styles from "styles/Retiro.module.css";
import { useSearchPackage } from "hooks/Dashboard/useSearchPackage";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import ReactPaginate from 'react-paginate';


const ListadoRetiro = () => {


	const { listSearchPackages, downloadSearch, isLoadingSearchPackages, formElement, handlePageChangeRetiro,
		pageCount, pageOffset, } = useSearchPackage();
	const { openModal, show  } = useModalWithDrawal(null)

	return (
		<Layout
			modulo="inicio"
			openModal={openModal}
			show={show}
		>
			
				<FormSearchAll 
					typeSearch="packages" 
					itemBreadcrumb="Mis retiros"
					urlBreadCrumb="/paquetes"
					formElement={formElement}
				/>
				<div className="row">
					<div className={`col-12 ${styles["height-table"]}`}>
						<div className="table-responsive">
							<table className={`table table-borderless ${styles["table-search"]}`}>
								<thead>
									<tr>
								      <th scope="col">#</th>
								      <th scope="col">Número de retiro</th>
								      <th scope="col">Nombre del remitente</th>
								      <th scope="col">Fecha</th>
									  <th scope="col">Sucursal</th>
									  <th scope="col">Comuna</th>
								      <th scope="col">Dirección</th>
								      <th scope="col">Estado</th>
								    </tr>
								</thead>
								<tbody>
									{
										listSearchPackages?.length>0 &&
										listSearchPackages?.map((item, key) => (
											<tr key={key}>
												<td></td>
												<td>{item.numero_retiro}</td>
												<td>{item.razon_social_remitente}</td>
												<td>{item.fecha}</td>
												<td>{item.sucursal_name}</td>
												<td>{item.comuna_name}</td>
												<td>{item.direccion_retiro}</td>
												<td>{item.label_web}</td>
											</tr>
										))
									}
									{(!isLoadingSearchPackages && listSearchPackages?.length===0) && (
										<tr>
											<td colSpan="6">No hay datos disponibles</td>
										</tr>
									)}
									{(isLoadingSearchPackages && listSearchPackages?.length===0) && (
										<tr>
											<td colSpan="6">Buscando datos...</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						
					</div>

					<div className={`col-12 ${styles["fixed-btn"]}`}>
						<div>
							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 mr-3 pl-5 pr-5 mb-3" 
								onClick={(event)=>downloadSearch(event, "pdf", "packages")} 
								disabled={listSearchPackages?.length===0 ? true : false}
							>
							Descargar PDF
							</button>

							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 pl-5 pr-5 mb-3"
								onClick={(event)=>downloadSearch(event, "excel", "packages")} 
								disabled={listSearchPackages?.length===0 ? true : false}
							>
							Descargar Excel
							</button>
						</div>
						<div>
							<ReactPaginate
								previousLabel="Anterior"
								nextLabel="Siguiente"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={handlePageChangeRetiro}
								containerClassName="pagination"
								activeClassName="active"
								forcePage={pageOffset}

							/>
						</div>
					</div>
				</div>

		
		</Layout>
	);
};

export default ListadoRetiro;