export const formatDashboard = (optionsDashboardMenu, list, option, title) => {
	let payload = { options: list, isLoading: false }		

	if(option){
		let packages = optionsDashboardMenu.map(item => {
			if(item.name===title){
				item.list = list
				item.listDsk = list
				item.isLoading = false
			}
			return item
		})
		payload = { ...payload, packages }
	}

	return payload
}

export const formatDateList = (datePackage) => {
	let dateCurrent = datePackage.split('-')

	return `${dateCurrent[0]} / ${dateCurrent[1]} / ${dateCurrent[2]}`
}