import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const MenuItem = ({ name, toUrl, subMenus, onClick, iconClassName }) => {

	const [ expand, setExpand ] = useState(false);

	const handleKeyDown = (e) =>{
        if (e.keyCode === 13) {
            onClick();
        }
    };

    const handleKeyDownExpand = (e) => {
		if (e.keyCode === 13) {
            setExpand(!expand);
        }
    };

	return (
		<li aria-hidden="true" onClick={()=>onClick()} onKeyDown={(e) =>handleKeyDown(e)}>
			<NavLink to={toUrl} activeClassName="active-menu-desk">
				<button onClick={()=>setExpand(!expand)} onKeyDown={(e) =>handleKeyDownExpand(e)} className="menu-item">
					<div className="menu-icon">
						<span className={iconClassName}></span>
					</div>
					<span>{name}</span>
				</button>
			</NavLink>
			{ 
				(subMenus && subMenus.length>0) && (
					<ul className={`sub-menu ${expand ? 'active' : ''}`}>						
						{subMenus.map((menu, index) => (
							<li key={index}>
								<Link to={menu.to}>{menu.name}</Link>
							</li>
						))}
					</ul>
				)
			}
			<div className="divider"></div>
		</li>
	);
};

export default MenuItem;