import stylesOffice from "styles/Office.module.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import sucursales_img from "assets/images/sucursal.png";


const LoadingOffice = () => {

	return (
        <>
			<section className={stylesOffice["container"]}>
				<p className={stylesOffice["title"]}><Skeleton height={20} count={1} /></p>
				<p className={stylesOffice["address"]}><Skeleton height={20} count={1} /></p>
				<div className={stylesOffice["item"]}>
					<img src={sucursales_img} alt="comuna" />
					<div className={stylesOffice["item-info"]}>
						<p className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-email"]}`}><Skeleton height={20} count={1} /></p>
						<p className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-telephone"]}`}><Skeleton height={20} count={1} /></p>
						<p className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-telephone"]}`}><Skeleton height={20} count={1} /></p>
					</div>
				</div>
			</section>
			<section>
				<h3 className={stylesOffice["dsk-title-h3"]}>Oficina</h3>
				<div>
					<p className={`${stylesOffice["title"]} mt-5`}><Skeleton height={20} count={1} /></p>
					<p className={stylesOffice["info-address"]}><Skeleton height={80} count={1} /></p>
				</div>
				<div>
					<p className={`${stylesOffice["title"]} ${stylesOffice["icon-time"]}`}>Horario</p>
					<p className={stylesOffice["info-address"]}>
                        <Skeleton height={60} count={1} />
					</p>
				</div>
			</section>
		</>
	)
}

export default LoadingOffice