import { 
	LIST_QUESTION,
	CHANGE_MENU_OPTIONS_QUESTION
} from 'services/types/questionType';
import { questions } from "data/dataQuestion.json"


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getDataInitial = () => (dispatch, getState) => {
	try{
	
		const payload = {
			listQuestionByFrequent: { type: "Preguntas frecuentes", list: questions},
			listQuestionByTransport: { type: "Preguntas sobre transporte", list: questions},
			listQuestionByPackage: { type: "Preguntas sobre paquetes", list: questions},
			listQuestionByPayments: { type: "Preguntas sobre pagos", list: questions},
			listQuestionByDefects: { type: "Preguntas sobre defectos", list: questions},
			listQuestionByLoss: { type: "Preguntas sobre pérdidas", list: questions},
		}
		
		dispatch(requestSuccess(LIST_QUESTION, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

/////////////////Cambio de optiones de menu Retiro
export const changeItemMenuOptionsQuestion = (item) =>  (dispatch, getState) => {
	try{

		const {  menuQuestion } = getState().questions

		let listItem = []

		let menuOptions = menuQuestion.map(menu => {
			menu.selected=false
			if(item.name===menu.name){
				menu.selected=true
				listItem = menu
			}

			return menu
		})
	

		const payload = {
			menuOptionsList: menuOptions,
			selected: listItem
		}

		dispatch(requestSuccess(CHANGE_MENU_OPTIONS_QUESTION, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};