import stylesOffice from "styles/Office.module.css";
import sucursales_img from "assets/images/sucursal.png";


const ItemOffice = ({ info, handleViewMapa }) => {

	return (
		<>
			<section className={stylesOffice["container"]}>
				<p className={stylesOffice["title"]}>{info.titulo}</p>
				<p className={stylesOffice["address"]}>{info.direccion}</p>
				<div className={stylesOffice["item"]}>
					<img src={sucursales_img} alt="comuna" />
					<div className={stylesOffice["item-info"]}>
						<div className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-email"]}`}>
							<a href={`mailto:${info.email}`}>{info.email}</a>
						</div>
						{
							info.telefonoArray.length>0 &&
							info.telefonoArray.map((item, key) =>
							<p key={key} className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-telephone"]}`}>{item}</p>
							)
						}
						<div 
							className={`${stylesOffice["item-info__text"]} mt-2 ${stylesOffice["item-info__link"]}  ${stylesOffice["icon-map"]}`}
							onClick={(event) => handleViewMapa(event, info.mapa)}
						>
							<a href="!#">Ver Mapa</a>
						</div>
					</div>
				</div>
			</section>
			<section>
				<p className={`${stylesOffice["title"]} ${stylesOffice["icon-time"]}`}>Horario</p>
				<div className={stylesOffice["info-address"]}>
					{
						info?.horarioArray?.length>0 &&
						info?.horarioArray?.map((item, key) =>
						<p key={key} className={`${stylesOffice["item-info__text"]}`}>{item}</p>
						)
					}
					
				</div>
			</section>
		</>
	)
}

export default ItemOffice