import { useSelector } from "react-redux"
import styles from "styles/Retiro.module.css";


const FormCompleted = ({ nextForm }) => {
	
	const { printNroRetiro } = useSelector((state) => state.preorden)

	return (
		<section>
			<h4 className={styles["title-completed"]}>Gracias</h4>
			<p className={styles["subtitle-completed"]}>Su nuevo retiro es: <b>{printNroRetiro}</b>, esta siendo procesado en estos momentos</p>
			<div className="mb-5 text-center">
				<button className="btn btn-secondary pl-5 pr-5 btn-size-33 mt-0" 
					onClick={()=>nextForm("preview")}>Imprimir ticket</button>
			</div>
		</section>
	);
};

export default FormCompleted;