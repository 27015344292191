import Slider from "components/Custom/Slider/Slider"
import styles from "styles/Retiro.module.css";
import Skeleton from 'react-loading-skeleton'
import { SwiperSlide } from 'swiper/react'
import 'react-loading-skeleton/dist/skeleton.css'

const CarrouselOrden = ({ preordens, loading }) => {

	return (
		<Slider
            options= {{
                className: "carousel-orden",
                navigation: true,
                pagination: false,
                slidesPerView: 'auto', 
            }}
            
        >
        {
            (!loading && preordens.length>0) &&
            preordens.map((item, key) => 
                <SwiperSlide key={key} className={styles["flex-container"]}>
                    
                        <div className={styles["det-orden-flex"]}>
                            <p className={styles["subtitle-flex"]}>Número de orden</p>
                            <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}>{item?.numero_preorden}</p>
                        </div>
                        <div className={styles["det-orden-flex"]}>
                            <p className={styles["subtitle-flex"]}>Cantidad de bultos</p>
                            <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}>{item?.cargas}</p>
                        </div>
                        <div className={styles["det-orden-flex"]}>
                            <p className={styles["subtitle-flex"]}>Destinatario</p>
                            <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}>{item?.destinatario}</p>
                        </div>
                        <div className={styles["det-orden-flex"]}>
                            <p className={styles["subtitle-flex"]}>Destino</p>
                            <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}>{item?.ciudad_destino_nombre}</p>
                        </div>
                        <div className={styles["det-orden-flex"]}>
                            <p className={styles["subtitle-flex"]}>Estado</p>
                            <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}>{item?.ultimo_estado}</p>
                        </div>
                    
                </SwiperSlide>
            )
        }
        {
            (loading) &&
            Array.from([1]).map((item, key) => 
                <div key={key} className={styles["flex-container"]}>
                    <div className={styles["det-orden-flex"]}>
                        <p className={styles["subtitle-flex"]}>Número de orden</p>
                        <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}><Skeleton width={100} height={20} count={1} /></p>
                    </div>
                    <div className={styles["det-orden-flex"]}>
                        <p className={styles["subtitle-flex"]}>Cantidad de bultos</p>
                        <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}><Skeleton width={100} height={20} count={1} /></p>
                    </div>
                    <div className={styles["det-orden-flex"]}>
                        <p className={styles["subtitle-flex"]}>Destinatario</p>
                        <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}><Skeleton width={100} height={20} count={1} /></p>
                    </div>
                    <div className={styles["det-orden-flex"]}>
                        <p className={styles["subtitle-flex"]}>Destino</p>
                        <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}><Skeleton width={100} height={20} count={1} /></p>
                    </div>
                    <div className={styles["det-orden-flex"]}>
                        <p className={styles["subtitle-flex"]}>Estado</p>
                        <p className={`${styles["subtitle-flex"]} ${styles["subtitle-flex__bold"]}`}><Skeleton width={100} height={20} count={1} /></p>
                    </div>
                </div>
            )
        }
        </Slider>
	)
}

export default CarrouselOrden