import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducers from "services/reducers/";

const bindMiddleware = (middleware) => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};
// create la función makeStore
export const store = createStore(
	rootReducers, 
	{}, 
	bindMiddleware([thunk])
);