import Layout from "components/Layout/Layout";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";

const BuscarOrden = () => {

	const { openModal, show  } = useModalWithDrawal(null)
	
	return (
		<Layout
			modulo="inicio"
			openModal={openModal}
			show={show}
		>
			<div className="container">
				

			</div>
		</Layout>
	);
};

export default BuscarOrden;