import styles from "styles/Retiro.module.css";
import { Link } from "react-router-dom";

const OrdenItem = ({ fecha, titleMain, valueMain, titleSecondary, valueSecondary, classBorder, classSend, type_option, url }) => {

	return (
		<section className={`${styles["option-container"]} ${styles[typeof classBorder!=="undefined" ? classBorder : ""]}`}>
			<div className="w-100">
				<div className={styles["option-item"]}>
					<p className={styles["item-text__title-fact"]}>{titleMain}</p>
					{
						type_option==="label" ? (
							<p className={`${styles["option-details__view"]} ${styles[typeof classSend!=="undefined" ? classSend : ""]}`}>{valueMain}</p>
						) : (
							<Link to={valueMain==="Ver Retiro" ? url : ""} className={styles["option-details__link"]}>
								<p className={`${styles["option-details__view"]} ${styles[typeof classSend!=="undefined" ? classSend : ""]}`}>{valueMain}</p>
							</Link>	
						)
					}
					
				</div>
				<div className={styles["option-item"]}>
					<p className={styles["item-text__subtitle-fact"]}>{titleSecondary}</p>
					<p className={styles["item-text__subtitle-fact"]}>{valueSecondary}</p>
				</div>
			</div>
		</section>
	);
};

export default OrdenItem;
