import { useEffect } from "react"
import IconInputPassword from "components/Password/IconInputPassword";
import delivery_mobile from "assets/images/delivery-mobile.svg";
import delivery_dsk from "assets/images/delivery-dsk.svg";
import logo from "assets/images/logo.svg";
import group_login from "assets/images/group-login.svg";
import styles from "styles/Login.module.css";
import { ToastContainer, toast } from 'react-toastify';
import { useLogin } from "hooks/Login/useLogin";

import { Controller } from "react-hook-form";
import MaskedInput from 'react-text-mask';
import RutTextMask from 'rut-text-mask';

export default function Login () {

	const { formElement, state, actions } = useLogin(toast)
	const { handleSearchClientByCode, setSearchClient } = actions;
	const { onSubmit, register, handleSubmit, errors, control } = formElement

	const { isLoading, switchShown, shown, listCodeInternal } = state

	useEffect(() => {
		if(localStorage.getItem("user")){
			localStorage.clear()
		} 
		setSearchClient(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className={`col-md-7 ${styles["bg-dsk"]}`}>
						<div className={styles["dsk-container"]}>
							<div>
								<img src={delivery_dsk} alt="Login" className={styles["dsk-container__img"]} height={255} />
							</div>
							<h1 className={styles["dsk-subtitle"]}><q>La innovación constante es el motor que impulsa el crecimiento y la relevancia en el mundo empresarial.</q></h1>
							<p className={styles["dsk-subtitle"]}>
								
								{" "}Jeff Bezos.
							</p>
							<div className={styles["dsk-image"]}>
								<img src={group_login} alt="Login" width={38} height={6} />
							</div>
						</div>
					</div>
					<div className="col-xs-12 col-md-5 mt-5 mb-5">
						<form className={styles["form-login"]} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<div className={styles["group-image__logo"]}>
								<img src={logo} alt="logo" width={147} height={96} />
							</div>
							<div className={styles["group-image"]}>
								<img src={delivery_mobile} alt="Login" width={230} height={128} />
							</div>
							<section className={styles["form-section"]}>
								<div className={`form-group ${errors?.rut?.message==="Requerido" ? "mb-1" : "mb-4"}`}>
									<Controller
										render={(props) => (
											<MaskedInput
												type="text"
												mask={RutTextMask}
												className={`form-control ${styles["form-input"]}`} 
												placeholder="RUT"
												id="rut" 
												name="rut"
												value={props.value}
												onChange={(value)=>{
													props.onChange(value)
												}}
												onBlur={(event) => handleSearchClientByCode(event)}
											/>
										)}
										control={control}
										name="rut"  
										rules={{required: {value: true, message: 'Requerido'}}}
									/>
								</div>
								{errors.rut && (
									<p className="text-danger mb-4 w-100 text-left">
										{errors?.rut?.message}
									</p>
								)}
								<div className={`form-group ${errors?.password?.message==="Requerido" ? "mb-1" : "mb-4"}`}>
									<input 
										type={shown ? 'text' : 'password'} 
										className={`form-control ${styles["form-input"]}`}
										id="password" 
										name="password"
										aria-describedby="password"
										placeholder="Contraseña"
										ref={register({required: {value: true, message: 'Requerido'}})}
									/>
									<IconInputPassword 
											shown={shown}
											switchShown={switchShown}
										/>	
								</div>
								{errors.password && (
									<p className="text-danger mb-4 w-100 text-left">
										{errors?.password?.message}
									</p>
								)}
								{
									listCodeInternal?.length>0 && (
										<>
											<div className={`form-group ${errors?.codigo_interno?.message==="Requerido" ? "mb-1" : "mb-4"}`}>
												<Controller
														control={control}
														name="codigo_interno"
														rules={{ required: {value: true, message: 'Requerido'} }}
														render = {(props) => {
															return (
															<select 
																value={props.value}
																className="form-control form-group-select"
																id="codigo_interno"   
																onChange={e => {                              
																	props.onChange(e.target.value);
																}}                         
															>
																<option value="">Seleccione el código interno</option>
																{ 
																	listCodeInternal.length>0 && listCodeInternal.map((item, key) => 
																		<option value={item.numero_cuenta} key={key}>{item.numero_cuenta}</option>
																	)
																}
															</select>
														)}}
												/>	
												
											</div>
											{errors.codigo_interno && (
												<p className="text-danger mb-4 w-100 text-left">
													{errors?.codigo_interno?.message}
												</p>
											)}
										</>
									)
								}								
								<div className={styles["group-check"]}>
									
									<div className="form-group text-right">
										<a href="/mi-cuenta/password" className={styles["text-small__link"]}>¿Olvidó su contraseña?</a>
									</div>
								</div>
								<div className="form-group">
									<button 
										type="submit" 
										className="btn btn-primary btn-block"
										disabled={isLoading}
									>{isLoading ? "Procesando..." : "Iniciar sesión"}</button>
								</div>
								{/**<div className="form-group">
									<button 
										type="submit" 
										className="btn btn-outline-primary btn-block"
									><span className={styles["icon-google"]}></span>Iniciar sesión con Google</button>
								</div>**/}								
								<div className="form-group">
									<p className={styles["text-small"]}>
										Aún no tienes una cuenta?
										<a href="/registrarse" className={styles["text-small__link"]}>Registrate</a>
									</p>
								</div>
							</section>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);
};