import OrdenItem from "components/Withdrawals/OrdenItem";
import Item from "components/Withdrawals/Detail/Item";
import CarrouselOrden from "components/Withdrawals/Detail/CarrouselOrden";
import styles from "styles/Retiro.module.css";
import { Link } from "react-router-dom";

const ContainerDetailOrden = ({ infoPackage, isVisibleButton, downloadInform, data, handleClose }) => {

	return (
		<>
            <OrdenItem 
                fecha=""
                titleMain={`#${infoPackage?.numero_retiro}`}
                tracking=""
                valueMain={infoPackage?.ultimo_estado}
                classBorder="paddinglr-0"
                type_option="label"
            />
            <div className={styles["detail-item"]}>
                <Item 
                    key={1}
                    title="Remitente"
                    subtitle={infoPackage?.razon_social_remitente}
                />
                <Item 
                    key={2}
                    title="Fecha"
                    subtitle={infoPackage?.fecha}
                />
                <Item 
                    key={3}
                    title="Cant ordenes de flete"
                    subtitle={infoPackage?.cant_preorden}
                />
                <Item 
                    key={4}
                    title="Oficina origen"
                    subtitle={`${infoPackage?.sucursal_name} ${infoPackage?.comuna_name || ""}`}
                />
                
                <section>
                    <p className={styles["title-det-orden"]}>Ordenes de flete asociadas</p>
                    <section className={styles["flex-all"]}>
                        <CarrouselOrden preordens={infoPackage?.preorden} loading={false} />
                    </section>
                    
                </section>
                <div className={styles["detail-item__container"]}>
                    <p className={`${styles["item-text__subtitle"]} mb-2 mt-2`}>Observación</p>
                    <p className={`${styles["item__title"]}`}>
                        {infoPackage?.observacion}
                    </p>
                    <hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
                </div>
            </div>
            
                    <div className={`${styles["detail-item__container"]} w-100 mt-3`}>
                        
                        <button 
                            className="btn btn-outline-primary btn-size-33 btn-block mb-3 font-10" 
                            onClick={(event) => handleClose(event)}
                        >
                            Descargar tickets
                        </button>
                        {
                            isVisibleButton && (
                                <Link to={{
                                    pathname:`retiro/${infoPackage?.numero_retiro}/informe`,
                                    state: infoPackage
                                }}>
                                    <button 
                                        className="btn btn-outline-primary btn-size-33 btn-block font-10" 
                                    >
                                    Ver informe
                                    </button>
                                </Link>
                        )
                    }
                    </div>
                
            
        </>
	);
};

export default ContainerDetailOrden;