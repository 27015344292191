import { useState } from "react"
import { saveFormReceive, nextFormPreOrden } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"


export const useReceive = (toast) => {

	const dispatch = useDispatch()
	const { preorden, offices } = useSelector((state) => {
		return {
			preorden: state.preorden,
			offices: state.offices
		}
	})
	const { formPreOrden }  = preorden
	const { sucursales } = offices
	const [oficinaOrigen, setOficinaOrigen ] = useState([])
	const [oficinaDestino, setOficinaDestino ] = useState([])

	const { register, handleSubmit, errors, control, setError, setValue } = useForm({
		defaultValues: {
			razon_social_remitente: formPreOrden?.razon_social_remitente,
			rut_remitente: formPreOrden?.rut_remitente,
			telefono_remitente: formPreOrden?.telefono_remitente,
			comuna_origen_id: formPreOrden?.comuna_origen_id,
			direccion_retiro: formPreOrden?.direccion_retiro,
			email_remitente: formPreOrden?.email_remitente
		}
	})

	const onSubmit =  (data, event) => {
		try{
			let sucursal_origen = document.querySelector("#sucursal_origen_id");
			let comuna_origen = document.querySelector("#comuna_origen_id");

			let body = {
				...data,
				comuna_name: sucursal_origen?.options[sucursal_origen.selectedIndex]?.text,
				sucursal_name: comuna_origen?.options[comuna_origen.selectedIndex]?.text
			}
			dispatch(saveFormReceive(body, "confirmationMultipleDestination"))
			dispatch(nextFormPreOrden("confirmationMultipleDestination"))
			
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const onChangeInput = (event, type, name) => {
		const { value } = event.target
		dispatch(saveFormReceive({ [name]: value!=="" ? value : ""}))
	}

	const cargarOficinas = (e, type, valor) => {

		const id =  (e !==undefined ) ? e.target.value : valor
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)

			}

			if(type === "origen") {
		    	setOficinaOrigen(detComuna) 
		    }else if(type === "destino") {
		    	setOficinaDestino(detComuna)
		    }
		}
			
	}

	useEffect(()=> {
		if(Object.keys(formPreOrden).length>0){
			cargarOficinas(undefined, "origen", formPreOrden?.sucursal_origen_id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		formPreOrden,
		onChangeInput,
		oficinaDestino,
		oficinaOrigen,
		cargarOficinas,
		setError,
		setValue
	}
}