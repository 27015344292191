import styles from "styles/Retiro.module.css";

const FormConfirmMultipleDestinatation =  ({ saveDestination, resetValudeDestination }) => {

	return (
		<section className={styles["confimartion-modal"]}>
			<p className={styles["text-confirmation"]}>¿Cómo desea hacer la carga de sus paquetes?</p>
			<div className={styles["btn-modal-confirm"]}>
			  	<button 
			  		type="button"
			  		className={`btn btn-outline-primary btn-block  ${styles["btn-options-modal"]}`}
			  		onClick={()=>resetValudeDestination("downloadTemplate")}
			  	>
		            Másiva
		        </button>
		        <button 
		        	type="button"
		        	className={`btn btn-outline-primary btn-block  ${styles["btn-options-modal"]}`}
		        	onClick={()=>saveDestination(false, "destination")}
		        >
		            Normal
		        </button>
			</div>
		</section>
	)
}

export default FormConfirmMultipleDestinatation