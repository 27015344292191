import Layout from "components/Layout/Layout";
import FormSearchAll from "components/Withdrawals/ViewAll/FormSearchAll";
import styles from "styles/Retiro.module.css";
import { useSearchPackage } from "hooks/Dashboard/useSearchPackage";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import ReactPaginate from 'react-paginate';


const ListadoOrders = () => {

	const { listSearchPackages, downloadSearch, isLoadingSearchPackages, handlePageChange,
		pageCount, pageOffset, formElement } = useSearchPackage();
	const { openModal, show  } = useModalWithDrawal(null)

	return (
		<Layout
			modulo="inicio"
			openModal={openModal}
			show={show}
		>
	
				<FormSearchAll 
					typeSearch="orders" 
					itemBreadcrumb="Mis ordenes"
					urlBreadCrumb="/paquetes"
					formElement={formElement}
				/>
				<div className="row">
					<div className={`col-12 ${styles["height-table"]}`}>
						<div className="table-responsive">
							<table className={`table table-borderless ${styles["table-search"]}`}>
								<thead>
									<tr>
									<th scope="col">#</th>
									<th scope="col">Número de orden</th>
									<th scope="col">Fecha</th>
									<th scope="col">Remitente</th>
									<th scope="col">Destinatario</th>
									<th scope="col">Dirección  destino</th>
									<th scope="col">Cant Bultos</th>
									<th scope="col">Estado</th>
									</tr>
								</thead>
								<tbody>
									{
										listSearchPackages?.length>0 &&
										listSearchPackages?.map((item, key) => (
											<tr key={key}>
												<td>{key+1}</td>
												<td>{item.numero_preorden}</td>
												<td>{item.fecha_preorden}</td>
												<td>{item.razon_social_remitente}</td>
												<td>{item.razon_social_destinatario}</td>
												<td>{item.direccion_destinatario}</td>
												<td>{item.total_bultos}</td>
												<td>{item.label_web}</td>
											</tr>
										))
									}
									{(!isLoadingSearchPackages && listSearchPackages?.length===0) && (
										<tr>
											<td colSpan="6">No hay datos disponibles</td>
										</tr>
									)}
									{(isLoadingSearchPackages && listSearchPackages?.length===0) && (
										<tr>
											<td colSpan="6">Buscando datos...</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>

					</div>
					<div className={`col-12 ${styles["fixed-btn"]}`}>
						<div>
							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 mr-3 pl-5 pr-5 mb-3" 
								onClick={(event)=>downloadSearch(event, "pdf", "packages")} 
								disabled={listSearchPackages?.length===0 ? true : false}
							>
							Descargar PDF
							</button>

							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 pl-5 pr-5 mb-3"
								onClick={(event)=>downloadSearch(event, "excel", "packages")} 
								disabled={listSearchPackages?.length===0 ? true : false}
							>
							Descargar Excel
							</button>
						</div>
						<div>
							<ReactPaginate
								previousLabel="Anterior"
								nextLabel="Siguiente"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={handlePageChange}
								containerClassName="pagination"
								activeClassName="active"
								forcePage={pageOffset}

							/>
						</div>
					</div>
				</div>

		
		</Layout>
	);
};

export default ListadoOrders;