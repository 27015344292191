import { useState } from "react"
import styles from "styles/Retiro.module.css";
import AccordionClaim from "components/ClaimAndSearch/AccordionClaim";

const PanelQuestion = ({ listQuestion }) => {
	
	const [ activeKey, setActiveKey ] = useState('0');

	return (
		<div className={`col-md-12 col-lg-12 mb-5 pb-5`}>
			
			<div className={styles["group-item__title"]}>
				<p className={`${styles["group-title"]} ${styles["group-title__calendar"]}`}>Preguntas Frecuentes</p>
				<p className={styles["group-view"]}></p>
			</div>
			<AccordionClaim 
				listFeatures={listQuestion} 
				activeKey={activeKey} 
				setActiveKey={setActiveKey} 
			/>
				
		</div>
	)
}

export default PanelQuestion