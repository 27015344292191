import { useEffect, useState } from "react"
import menu_burger from "assets/images/menu/menu-burger.png";
import menu_user from "assets/images/menu/user.png";
import menu_logo from "assets/images/menu/logo.png";
import { Link, useLocation } from "react-router-dom";

const MenuTop = () => {

	const location = useLocation()
	const [ url, setUrl ] = useState("/perfil")

	useEffect(() => {
		if(location?.pathname==="/perfil"){
			setUrl(url => "/paquetes")
		}else{
			setUrl(url => "/perfil")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.pathname])

	return (
		<section className="menu-top">
			<div className="menu-top__icon">
				<img src={menu_burger} alt="Menu" width={28} heigth={28} />
			</div>
			<div className="menu-top__icon">
				<img src={menu_logo} alt="Menu" width={126} heigth={19} />
			</div>
			<div className="menu-top__icon">
				<Link to={url}>
					<img src={menu_user} alt="Menu" width={28} heigth={24} />
				</Link>
			</div>
		</section>
	);
};

export default MenuTop;