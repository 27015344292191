import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import CardAccordion from 'components/Custom/Accordion/CardAccordion';
import styles from "styles/Retiro.module.css";


const AccordionPreOrden = ({ listFeatures }) => {

	const [ activeKey, setActiveKey ] = useState('0')

	return (
		<Accordion activeKey={activeKey} onSelect={(event) => setActiveKey(event)}>
			{
				listFeatures.map((info, key) => (
					 <CardAccordion
					  		title={`Preorden ${info.numero_preorden}`}
					  		key={key}
					  		index={key}
					  		activeKey={activeKey}
					 	>
					 	<div className="details-car">
                            
                                    <div>
                                        <div className={styles["card"]}>
                                            <div className={styles["item-card--main"]}>
                                                <p className={styles["title-card"]}>Destinatario</p>
                                                <p className={styles["subtitle-card"]}>{info?.razon_social_destinatario}</p>  
                                            </div>
                                            <div className={styles["item-card--main"]}>
                                                <p className={styles["title-card"]}>Destino</p>
                                                <p className={styles["subtitle-card"]}>{`${info?.ciudad_destino_nombre} - ${info?.oficina_destino_nombre}`}</p>  
                                            </div>
                                        </div>	
                                        {
                                            info?.listEstado.length>0 &&
                                            info?.listEstado.map((item, key) => 
                                                <div key={key} className={`${styles["card"]} ${styles["card-line"]}`}>
                                                    <div className={styles["item-card"]}>
                                                        <p className={styles["title-card"]}>Fecha</p>
                                                        <p className={styles["subtitle-card"]}>{item?.fecha}</p>  
                                                    </div>
                                                    <div className={styles["item-card"]}>
                                                        <p className={styles["title-card"]}>Hora</p>
                                                        <p className={styles["subtitle-card"]}>{item?.hora}</p>  
                                                    </div>
                                                    <div className={styles["item-card"]}>
                                                        <p className={styles["title-card"]}>Estatus</p>
                                                        <p className={styles["subtitle-card"]}>{item?.descripcion}</p>  
                                                    </div>
                                                    <div className={styles["item-card"]}>
                                                        <p className={styles["title-card"]}>Observación</p>
                                                        <p className={styles["subtitle-card"]}>{item?.observacion} </p>  
                                                    </div>
                                                </div>	
                                            )
                                        }
                                    </div>	
                               
										 
				      	</div>	
					</CardAccordion>
				))
			}
		</Accordion>
	)
}

export default AccordionPreOrden