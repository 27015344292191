import IconInputPassword from "components/Password/IconInputPassword";
import { useFormPassword } from "hooks/Profile/useFormPassword"
import { useUtil } from "hooks/Utils/useUtil"
import styles from "styles/Profile.module.css";


const FormPassword = ({ toast }) => {

    const { formElement, state } = useFormPassword(toast)
    const {  switchShown: switchShownCurrent, shown: shownCurrent } = useUtil(toast)
    const { switchShown: switchShownPass, shown:shownPass } = useUtil(toast)
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil(toast)

    const { register,  handleSubmit, errors, onSubmit } = formElement
    const { isLoading } = state


    return (
        <form className={styles["form-account"]} onSubmit={handleSubmit(onSubmit)}>
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.passwordCurrent?.message!=="undefined" && errors?.passwordCurrent?.message!=="") ? "mb-1" : "mb-5"}`}>
            <label htmlFor="passwordCurrent" className={`${styles["label-title"]} mb-0`}>Contraseña actual</label>
                <div className="p-relative">
                    <input 
                        type={shownCurrent ? 'text' : 'password'}  
                        className={`form-control ${styles["form-input"]}`}
                        id="passwordCurrent" 
                        name="passwordCurrent"
                        aria-describedby="passwordCurrent"
                        placeholder="Contraseña"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                    />
                    <IconInputPassword 
                        shown={shownCurrent}
                        switchShown={switchShownCurrent}
                />
                </div>
                </div>
                {(Object.keys(errors).length>0 && typeof errors?.passwordCurrent?.message!=="undefined" && errors?.passwordCurrent?.message!=="")  && (
                <p className="text-danger mb-2 w-100 text-left">
                    {errors?.passwordCurrent?.message}
                </p>
            )}
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.password?.message!=="undefined" && errors?.password?.message!=="") ? "mb-1" : "mb-5"}`}>
                <label htmlFor="password" className={`${styles["label-title"]} mb-0`}>Contraseña nueva</label>
                <div className="p-relative">
                    <input 
                        type={shownPass ? 'text' : 'password'} 
                        className={`form-control ${styles["form-input"]}`}
                        id="password" 
                        name="password"
                        aria-describedby="password"
                        placeholder="Contraseña"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                    />
                    <IconInputPassword 
                        shown={shownPass}
                        switchShown={switchShownPass}
                    />
                </div>
                </div>
                {(Object.keys(errors).length>0 && typeof errors?.password?.message!=="undefined" && errors?.password?.message!=="")  && (
                <p className="text-danger mb-2 w-100 text-left">
                    {errors?.password?.message}
                </p>
            )}
            <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.repPassword?.message!=="undefined" && errors?.repPassword?.message!=="") ? "mb-1" : "mb-5"}`}>
                <label htmlFor="repPassword" className={`${styles["label-title"]} mb-0`}>Repetir contraseña nueva</label>.
                <div className="p-relative">
                    <input 
                        type={shownRep ? 'text' : 'password'} 
                        className={`form-control ${styles["form-input"]}`}
                        id="repPassword" 
                        name="repPassword"
                        aria-describedby="repPassword"
                        placeholder="Confirmar contraseña"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                    />
                    <IconInputPassword 
                        shown={shownRep}
                        switchShown={switchShownRep}
                    />
                </div>
                </div>
                {(Object.keys(errors).length>0 && typeof errors?.repPassword?.message!=="undefined" && errors?.repPassword?.message!=="")  && (
                <p className="text-danger mb-2 w-100 text-left">
                    {errors?.repPassword?.message}
                </p>
            )}
            <div className="form-group text-right">
                <button 
                    type="submit" 
                    className="btn btn-secondary pl-5 pr-5 mb-4 pt-3 pb-3"
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Guardar"}</button>
                </div>
        </form>
    )
}

export default FormPassword