const API = process.env.REACT_APP_API_URL;

const endPoints = {
  auth: {
    login: `${API}/auth/web/login`,
    logout: `${API}/auth/web/logout`,
    bycode: `${API}/auth/web/client/code`
  },
  retiros: {
    addRetiros: `${API}/web/packages`,
    getListAll: (page, limit) => `${API}/web/packages/all?page=${page}&limit=${limit}`,
    previewRetiro: (id, page) =>`${API}/web/packages/print/${id}?page=${page}`,
    previewTicketRetiro: (id) => `${API}/web/packages/ticket/${id}`,
    countPackage: `${API}/web/packages/send`,
    printExcelPdf: `${API}/web/packages/download`,
    getInfoPackage: (numero_retiro) =>`${API}/web/packages/${numero_retiro}/search`,
    downloadInfo: `${API}/web/packages/informe`,
    createRetiros: `${API}/web/packages/create`,
    assignOrden: (id) =>`${API}/web/packages/${id}/assign/orden`,    
  },
  orders: {
    getListAll: (page, limit) => `${API}/web/orders/all?page=${page}&limit=${limit}`,
    printExcelPdf: `${API}/web/orders/download`,
    notification: (page, limit) => `${API}/web/orders/notification?page=${page}&limit=${limit}`,
  },
  inovice: {
  	getInoviceClient: (page, limit) => `${API}/web/invoice/list?page=${page}&limit=${limit}`,
  	getInoviceCompany: (page, limit) => `${API}/web/invoice/company?page=${page}&limit=${limit}`
  },
  client: {
    register: `${API}/auth/web/register`,
  },
  password: {
    forgot: `${API}/auth/web/password/reset`,
    code: `${API}/auth/web/password/code`,
    reset: `${API}/auth/web/password/new`
  },
  profile: {
    password: `${API}/web/profile/password`,
    client: `${API}/web/profile/client`,
  },
  office: {
    info: `${API}/web/sucursals`,
  },
  consultationAndComplaint: {
    sendForm: `${API}/web/consult-claim/send-form`,
    list: `${API}/web/consult-claim/list`,
    getMessage: `${API}/web/consult-claim/conversation`,
    sendRepley: `${API}/web/consult-claim/send-repley`,
    updateMessage: `${API}/web/consult-claim/message/status`
  },
  formaPago: {
    list: `${API}/web/forma-pagos`,
  },
};

export default endPoints;