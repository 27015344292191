import IconInputPassword from "components/Password/IconInputPassword";
import styles from "styles/Login.module.css";
import { useUtil } from "hooks/Utils/useUtil"


const FormNewPassword = ({ register, errors, isLoading, toast }) => {

	const { switchShown: switchShownPass, shown:shownPass } = useUtil(toast)
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil(toast)

    return (
        <>
            <div className={`form-group`}>
					<h3 className={styles["title-account"]}>Resetear <br/>la contraseña</h3>
				</div>
				<div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.password?.message!=="undefined" && errors?.password?.message!=="") ? "mb-1" : "mb-4"}`}>
				    <input 
				    	type={shownPass ? 'text' : 'password'}  
				    	className={`form-control ${styles["form-input"]}`}
				    	id="password" 
				    	name="password"
				    	aria-describedby="password"
				    	placeholder="Nueva contraseña"
				    	ref={register({required: {value: true, message: 'Requerido'}})}
				    />
				    <IconInputPassword 
						shown={shownPass}
						switchShown={switchShownPass}
					/>
				 </div>
				 {errors.password && (
			    	<p className="text-danger mb-4 w-100 text-left">
			      		{errors?.password?.message}
			    	</p>
			    )}
			    <div className={`form-group ${(Object.keys(errors).length>0 && typeof errors?.repPassword?.message!=="undefined" && errors?.repPassword?.message!=="") ? "mb-1" : "mb-4"}`}>
				    <input 
				    	type={shownRep ? 'text' : 'password'}
				    	className={`form-control ${styles["form-input"]}`}
				    	id="repPassword" 
				    	name="repPassword"
				    	aria-describedby="repPassword"
				    	placeholder="Confirmar nueva contraseña"
				    	ref={register({required: {value: true, message: 'Requerido'}})}
				    />
				    <IconInputPassword 
						shown={shownRep}
						switchShown={switchShownRep}
					/>
				 </div>
				 {errors.repPassword && (
			    	<p className="text-danger mb-4 w-100 text-left">
			      		{errors?.repPassword?.message}
			    	</p>
			    )}
				 <div className="form-group">
				    <button 
				    	type="submit" 
				    	className="btn btn-primary btn-block mb-4 pt-2"
				    	disabled={isLoading}
				    >{isLoading ? "Procesando..." : "Siguiente"}</button>
				 </div>
        </>
    )
}

export default FormNewPassword