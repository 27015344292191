import styles from "styles/Retiro.module.css";


const FormCompleted = () => {
	

	return (
		<section>
			<h4 className={styles["title-completed"]}>Gracias</h4>
			<p className={styles["subtitle-completed"]}>El reclamo o la consulta se ha enviado satisfactoriamente</p>
		</section>
	);
};

export default FormCompleted;