import { useCallback, useEffect } from 'react'
import Dropzone from 'components/Custom/Dropzone/index'
import styles from "styles/Retiro.module.css";
import { useUploadTemplate } from "hooks/WithDrawals/useUploadTemplate"
import { useReceive } from "hooks/WithDrawals/useReceive"
import { Controller } from "react-hook-form"
import icon_excel from "assets/images/icon-excel.svg"
import { BiX } from "react-icons/bi"


const FormFile = ({ nextForm, sucursales, toast, listFormaPagos }) => {

	const { downloadFile, control, handleSubmit, onSubmit, errors, updateFiles, listSend, getDataPreview, items,
		deleteItemTable, configFormData, setListSend } = useUploadTemplate(toast)
	const { cargarOficinas, oficinaDestino } = useReceive(toast)
	
	const onDrop = useCallback((acceptedFiles, fileRejections) => {
		updateFiles(acceptedFiles, fileRejections)	
		// eslint-disable-next-line 
	}, [])

	useEffect(() => {
	
		if(configFormData?.length>0){
			setListSend(listSend => configFormData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configFormData])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className="mt-4">
				<div className="row mb-4">
				    <div className="col-12">
                        <p className={styles["text-template"]}>Para la carga másiva realice los siguientes pasos:</p>
						<section className={styles["section-step"]}>
							<div className={styles["section-step-item"]}>
								<p className={`${styles["text-step"]} mb-0`}>
									1. Descargar la plantilla en excel 
								</p>	
								<section className={styles["container-template"]}>
									<div className={styles["template-div-img"]}>
										<img src={icon_excel} alt="Descargar" />
									</div>
									<a href="!#" className={styles["text-template--link"]} onClick={(event)=>downloadFile(event)}>Descargar aquí</a>
								</section>
							</div>
							<div className={styles["section-step-item"]}>
								<p className={`${styles["text-step"]} mb-0`}> 2. La plantilla debe llevar los siguientes datos</p>	
								<div className={styles["container-ul"]}>
									<ul className={styles["li-item"]}>
										<li>Nombre</li>
										<li>RUT </li>
										<li>Dirección destino</li>
										<li>Número contacto</li>
									</ul>	
									<ul className={styles["li-item"]}>
										<li>Correo</li>
										<li>Cant bulto</li>
										<li>Peso</li>
									</ul>
								</div>			
							</div>
						</section>
						<p className={`${styles["text-step"]} mb-3`}>3. El RUT debe contener punto(.) y guion (-), ejemplo: 00.000.000-0</p>

						<p className={styles["text-step"]}>4. Seleccione la Ciudad, la Comuna y adjunte la plantilla con los datos de los destinatarios y sus paquetes</p>
						<section className={styles["section-step"]}>
							<div className={styles["section-step-item"]}>
								<section className={styles["container-select-file"]}>
									<div className={`mb-4 ${styles["select-file"]}`}>
										<label className={styles["form-withdrawals__label"]} htmlFor="sucursal_destino_id">Ciudad:</label>
										<Controller
												control={control}
												name="sucursal_destino_id"
												rules={{ required: {value: true, message: 'Requerido'} }}
												render = {(props) => {
													return (
													<select 
														value={props.value}
														className="form-control"
														id="sucursal_destino_id"   
														onChange={e => {                              
															props.onChange(e.target.value);
															cargarOficinas(e, "destino", "")
														}}                         
													>
														<option value="">-Seleccione-</option>
														{
															sucursales.length>0 && sucursales.map((item, key) => 
																<option value={item.id} key={key}>{item.nombre}</option>
															)
														}
													</select>
												)}}
										/>	
										{(errors.sucursal_destino_id && errors.sucursal_destino_id.message!=="") && (
											<p className="text-danger mb-4">
												{errors?.sucursal_destino_id?.message}
											</p>
										)}
									</div>
									<div className={`mb-4 ${styles["select-file"]}`}>
										<label className={styles["form-withdrawals__label"]} htmlFor="comuna_destino_id">Comuna:</label>
										<Controller
											control={control}
											name="comuna_destino_id"
											rules={{ required: {value: true, message: 'Requerido'} }}
											render = {(props) => {
												return (
												<select 
													value={props.value}
													className="form-control"
													id="comuna_destino_id"   
													onChange={e => {                              
														props.onChange(e.target.value);
													}}                         
												>
													<option value="">-Seleccione-</option>
													{
														oficinaDestino.length>0 && oficinaDestino.map((item, key) => 
															<option value={item.id} key={key}>{item.nombre}</option>
														)
													}
												</select>
											)}}
										/>	
										{(errors.comuna_destino_id && errors.comuna_destino_id.message!=="") && (
											<p className="text-danger mb-4">
												{errors?.comuna_destino_id?.message}
											</p>
										)}
									</div>
									<div className={styles["select-file"]}>
										<label className={styles["form-withdrawals__label"]} htmlFor="forma_pago_id">Forma de pago:</label>
										<Controller
											control={control}
											name="forma_pago_id"
											rules={{ required: {value: true, message: 'Requerido'} }}
											render = {(props) => {
												return (
												<select 
													value={props.value}
													className="form-control"
													id="forma_pago_id"   
													onChange={e => {                              
														props.onChange(e.target.value);
													}}                         
												>
													<option value="">-Seleccione-</option>
													{
														listFormaPagos.length>0 && listFormaPagos.map((item, key) => 
															<option value={item.id} key={key}>{item.descripcion}</option>
														)
													}
												</select>
											)}}
										/>	
										{(errors.forma_pago_id && errors.forma_pago_id.message!=="") && (
											<p className="text-danger mb-4">
												{errors?.forma_pago_id?.message}
											</p>
										)}
									</div>										
								</section>				
							</div>				
							<div className={`${styles["section-step-item"]} mt-5`}>
								<Dropzone onDrop={onDrop} items={items} />				
							</div>	
						</section>
						<section>	
							<div className="mb-3 text-right">
								<button 
									type="submit"
									className="btn btn-secondary btn-size-33 pl-5 pr-5 mt-0" 
								>
									Agregar
								</button>		
							</div>	
						</section>
				    </div>
				</div>
				<div className="row mb-5 pb-5">
					<div className="col-12">
						<p className="text-count-destination">La cantidad máxima de destintario es 1.000</p>
						<div className="table-responsive">
							<table className={`table table-bordered ${styles["table-step"]}`}>
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Ciudad</th>
										<th scope="col">Comuna</th>
										<th scope="col">Forma de pago</th>
										<th scope="col">Archivo</th>
										<th scope="col">Cant Destintario</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										listSend.length>0 &&
										listSend.map((item, index) => 
											<tr key={index}>
												<th scope="row">{index+1}</th>
												<td>{item.sucursal_name}</td>
												<td>{item.comuna_name}</td>
												<td>{item.forma_pago}</td>
												<td>{item.path}</td>
												<td>{item.cant}</td>
												<td>
													<button 
														type="button"
														className="btn btn-danger"
														onClick={(event) => deleteItemTable(event, item)}
													>
														<BiX style={{fontSize: '20px'}} />
													</button>
												</td>
											</tr>
										)
									}
									{
										listSend.length===0 && (
											<tr>
												<td colSpan={7}>No hay datos disponibles</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className={styles["btn-modal"]}>
				  	<button 
				  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
				  		onClick={()=>nextForm("confirmationMultipleDestination")}
				  	>
			            Atrás
			        </button>
			        <button 
			        	type="button"
			        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
						onClick={()=>getDataPreview()}
			        >
			            Vista Previa
			        </button>
				</div>
				<div className="row mb-3">
				  	<div className={`col ${styles["item-container"]}`}>
				  		<div className={styles["item-selected"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  	</div>
				</div>
			</section>
		</form>
	);
};

export default FormFile;