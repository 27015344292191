import styles from "styles/Retiro.module.css";

const Item = ({ title, subtitle}) => {

	return (
		<div className={styles["detail-item__container"]}>
			<div className={`${styles["detail-item__text"]}`}>
				<p className={`${styles["item-text__subtitle"]} mb-0`}>{title}</p>
				<p className={`${styles["item__title"]} mb-0`}>{subtitle}</p>
			</div>
			<hr className={`${styles["item__title-border"]} mb-0 mt-0`} />
		</div>
	);
};

export default Item;