import { useState } from "react"
import { registerClient } from "services/actions/userAction"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { validarRut } from "utils/ToolRUT/"
import md5 from "md5";

export const useRegister = (toast) => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ isSuccess, setIsSuccess ] = useState(false)
	const [ isDisabled, setIsDisabled ] = useState(true)

	const { register, handleSubmit, errors, setError, setValue, control } = useForm({
		defaultValues: {
			r_email: "",
			password: ""
		}
	})

	const onSubmit = async (data, event) => {
		try{
			
			if(!isDisabled){
				toast.error("Debe aceptar los términos y condiciones", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			setIsLoading(true)

			const responseRut = validarRut(data.rut)
			if(responseRut?.status===false){
				setError("rut", { type: "manual", message: responseRut?.message})
				setIsLoading(false)
				return false
			}else{
				setError("rut", { type: "manual", message: ""})
			}

			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(data.r_email)
            if(!isValidEmail){
                setValue("r_email", "")
                setError("r_email", { type: "manual", message: `El e-mail ingresado es incorrecto`})
                setIsLoading(false)
            	return false
            }

            if(data.telephone.length<9){
				setError("telephone", { type: "manual", message: `El número ingresado es incorrecto`})
				setIsLoading(false)
            	return false
            }

            regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
			const isValidTelephone = regex.test("+56"+data.telephone)
            if(!isValidTelephone){
                setValue("telephone", "")
				setIsLoading(false)
            	return false
            }

            if(data.password.length<6){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

			if(data.repPassword.length<6){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

            if(data.password!==data.repPassword){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser iguales", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

			let body = {
				password: md5(data?.password),
				fullname: data?.fullname,
				rut: data?.rut,
				telephone: "+56"+data.telephone,
				email: data?.r_email
			}


			let response = await dispatch(registerClient(body))
			
			setIsLoading(false)

			if(response.status){
				setIsSuccess(true)
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}

		}catch(error){
			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const state = {
		isLoading,
		isSuccess,
		isDisabled	
	}


	const formElement = {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		setError,
		setValue
	}

	const actions = {
		setIsDisabled
	}

	return {
		state,
		formElement,
		actions		
	}
}