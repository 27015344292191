import { 
	LOGIN,
	LOGOUT,
	FORGOT_PASSWORD
} from 'services/types/userType';

const INITIAL_STATE = {
	userData: localStorage.getItem("user") ? JSON.parse(atob(localStorage.getItem("user"))) : null,
	emailForgot: "",
	rutForgot: ""
};

const userReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOGIN:
			return {
				...state,
				userData: action.payload
			}
		case LOGOUT:
			return {
				...state,
				userData: action.payload
			}
		case FORGOT_PASSWORD:
			return {
				emailForgot: action.payload.email,
				rutForgot: action.payload.rut
			}
		default: return state;
	}
};

export default userReducer;