import styles from "styles/Retiro.module.css";

const MainContainer = ({ title, subtitle }) => {

	return (
		<>
			<h1 className={styles["orden-title"]}>{title}</h1>
			<p className={styles["orden-subtitle"]}>{subtitle}</p>
		</>
	)
}

export default MainContainer