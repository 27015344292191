import {useDropzone} from 'react-dropzone';
import styles from "styles/Retiro.module.css";
import icon_upload from 'assets/images/icon-upload.svg';

const Dropzone = ({ onDrop, items }) => {

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': []
        },
        onDrop,
        maxSize: 1000000 
    });


	return (
        <>
		<section className={styles["dropzone-container"]}>
            <div {...getRootProps({className: 'dropzone'})}>
                <input 
                    {...getInputProps()} 
                />
                <div className={styles["container-ul"]}>
                    <div className={styles["dropzone-icon"]}>
                        <img src={icon_upload} alt="Subir archivo" />
                    </div>
                    <div>
                        <p className={styles["dropzone-text"]}>Arrastra tu archivo aquí (.xlsx, .xls)</p>
                        <p className={styles["dropzone-text"]}>Tamaño máximo: 1MB</p>
                    </div>
                </div>
            </div>
            
        </section>
        <p className="text-center text-path">{items?.path}</p>
    </>
	)
}

export default Dropzone