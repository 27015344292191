import Layout from "components/Layout/Layout";
import MainContainer from "components/Custom/MainContainer"
import OrdenItem from "components/ClaimAndSearch/OrdenItem";
import ModalInfoClaim from "components/ClaimAndSearch/Form/ModalInfoClaim"
import FormClaim from "components/ClaimAndSearch/Form/FormClaim";
import PanelSpinner from "components/Custom/Spinner/PanelSpinner";
import ModalClaimSearch from "components/ClaimAndSearch/ModalClaimSearch";
import MediaQuery from 'react-responsive'
import styles from "styles/Retiro.module.css";
import icon_inbox from "assets/images/icon-inbox.svg";
import { useClaim } from "hooks/Claims/useClaim";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import { toast } from 'react-toastify'

const Reclamos = () => {

	const { state, actions } = useClaim()

	const { listClaimsSearch, loadingClaim, show, showModal, claimSelected, modalName, titleModal, loadingModal 	} = state

	const { handleClose, openModalByType, viewClaim, handleCloseModal } = actions
	const { openModal, show: showModalOpen  } = useModalWithDrawal(null)

	return (
		<Layout
			modulo="reclamos-y-consultas"
			openModal={openModal}
			show={showModalOpen}
		>
			<MediaQuery maxWidth={767}>
				<section className={styles["row-top"]}>
						<MainContainer
							title="Reclamos"
							subtitle="Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee."
						/>
						<div>
							<button 
								className="btn btn-outline-primary btn-size-33 btn-block mb-3 font-10" 
								onClick={()=>openModalByType("frm-initial")}
							>
								Generar un reclamo / consulta
							</button>
						</div>
				</section>
			</MediaQuery>
			
			<div className={`row ${styles["pb-row-10"]}`}>
				<MediaQuery minWidth={767}>
					<div className="col-md-12 col-lg-5 pr-md-5">
						<div className={styles["group-item__title"]}>
							<p className={`${styles["group-title"]} ${styles["group-title__calendar"]}`}>Generar reclamo</p>
							<p className={styles["group-view"]}></p>
						</div>
						<FormClaim 
							toast={toast}
							handleCloseOpen={handleClose}
							title="Motivo del reclamo"
							subtitle="Detalle del reclamo"
							type="form"
						/>
					</div>
				</MediaQuery>
				<div className={`col-md-12 col-lg-6 ${styles["history-claim"]}`}>
					<div className={styles["group-item__title"]}>
						<p className={`${styles["group-title"]} ${styles["group-title__calendar"]}`}>Historial de reclamos / consultas</p>
					</div>
					{
						loadingClaim && <PanelSpinner />
					}
					{
						(!loadingClaim && listClaimsSearch?.length>0) &&
						listClaimsSearch?.map((item, index) => 
							<OrdenItem 
								key={index} 
								{...item} 
								classBorder="" 
								classSend="cursor" 
								viewClaim={viewClaim}
							/>
						)
					}
					{
						(!loadingClaim && listClaimsSearch?.length===0) &&
						<div className={styles["options-not-records"]}>
								<img src={icon_inbox} alt="No hay registros" />
								<p className={styles["item-text__subtitle-fact"]}>
									No hay reclamos o consultas disponibles
								</p>
							</div>
					}
				</div>
			</div>

			{show && 
				<ModalInfoClaim 
					show={show} 
					handleClose={handleClose}
					claimSelected={claimSelected}
					title={titleModal}
					loadingModal={loadingModal}
				/>
			}

			{showModal && 
				<ModalClaimSearch 
					show={showModal} 
					handleClose={handleCloseModal}
					toast={toast}
					modalName={modalName}
				/>
			}
		</Layout>
	)
}

export default Reclamos