import { NavLink } from "react-router-dom";

const MenuBottom = ({ menuItems }) => {

	return (
		<section className="menu-bottom">
			{
				menuItems.map((menu, index) => (
					<NavLink to={menu.to} key={index} activeClassName="active-menu-mob">
						<div  className="menu-bottom__item">
							<div className="item-icon">
								<span className={menu.iconClassName}></span>
							</div>
							<p className="item-title">{menu.titleMobile}</p>
						</div>
					</NavLink>
				))
			}
		</section>
	);
};

export default MenuBottom;