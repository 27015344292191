import { useEffect } from "react"
import { getDataInitial, changeItemMenuOptionsQuestion } from "services/actions/questionAction"
import { useDispatch, useSelector } from "react-redux"


export const useQuestion = (toast) => {

	const dispatch = useDispatch()
	const { listQuestionSelected, menuQuestion, listQuestionByFrequent, listQuestionByTransport,
		listQuestionByPackage, listQuestionByPayments, listQuestionByDefects, listQuestionByLoss } = useSelector((state) => state.questions)

	const getInitial = async () => {
		await dispatch(getDataInitial())
	}

	const changeOptions = (item) => {
		dispatch(changeItemMenuOptionsQuestion(item))
	}

	useEffect(() => {
		getInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		listQuestionSelected,
		menuQuestion,
		changeOptions,
		listQuestionByFrequent,
		listQuestionByTransport,
		listQuestionByPackage,
		listQuestionByPayments,
		listQuestionByDefects,
		listQuestionByLoss
	}
}