import { useEffect } from "react"
import { changeItemMenuOptionsRetiro, loadingRetiroPanel, getInvoiceByClientRetiro,
	getInfoPackageInitial, getMyPackagesListRetiro, getMyOrdersListRetiro } from "services/actions/withdrawalAction"
import { useDispatch, useSelector } from "react-redux"


export const useDashboard = (toast) => {

	const dispatch = useDispatch()
	const { isLoadingRetiro, menuOptionsListRetiro, listGeneralRetiro } = useSelector((state) => state.preorden)

	const getInitial = async () => {
		dispatch(loadingRetiroPanel(true))
		dispatch(getInfoPackageInitial())
		
		if(menuOptionsListRetiro[0].list.length===0 && menuOptionsListRetiro[1].list.length===0){
	
			await dispatch(getMyPackagesListRetiro(1, 7, null))
			await dispatch(getMyOrdersListRetiro(1, 7, null))
			await dispatch(getInvoiceByClientRetiro(1, 7))


			/**if(responseMyOrders.status && responseMypackages.status){
				dispatch(getMyNotification())	
			}
			await dispatch(getInvoiceByClient())
			await dispatch(getPackageSendCount())**/
		}
		dispatch(loadingRetiroPanel(false))
	
	}

	const changeOptionsRetiro = (item) => {
		dispatch(changeItemMenuOptionsRetiro(item))
	}

	const downloadFile = (event, url, number_invoice) => {
		event.preventDefault()
	
		let url_documento = url
		if(url.includes("http")){
			url_documento = url.replace("http", "https")
		}
		let a = document.createElement('a');
		a.href = url_documento;
		a.download = `documento_${number_invoice}.pdf`;
		a.click();
	}

	useEffect(() => {
		getInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		isLoadingRetiro, 
		menuOptionsListRetiro, 
		listGeneralRetiro,
		changeOptionsRetiro,
		downloadFile
	}
}