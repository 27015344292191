import { 
	LIST_CLAIMS,
	LOADING_CLAIMS,
	ADD_CLAIM_SEARCH,
	SELECT_CLAIM,
	RESET_FORM_CLAIM,
	LOADING_MODAL_CLAIM_DETAIL
} from 'services/types/claimType';


const INITIAL_STATE = {
	listClaimsSearch: [],
	loadingClaim: true,
	modalName: { title: "", form: ""},
	listFeaturesClaim: [],
	claimSelected: [],
	loadingModal: false
};

const claimReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_CLAIMS:
			return {
				...state,
				listClaimsSearch: action.payload.listClaimsSearch,
				loadingClaim: action.payload.loadingClaim
			}
		case LOADING_CLAIMS:
			return {
				...state,
				loadingClaim: action.payload
			}
		case ADD_CLAIM_SEARCH:
			return {
				...state,
				listClaimsSearch: action.payload.listClamsNew
			}
		case SELECT_CLAIM:
			return {
				...state,
				claimSelected: action.payload.claimSelected
			}
		case RESET_FORM_CLAIM:
			return {
				...state,
				modalName: action.payload.formViewActive
			}
		case LOADING_MODAL_CLAIM_DETAIL:
			return {
				...state,
				loadingModal: action.payload
			}
		default: return state;
	}
};

export default claimReducer;